import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import nlLocale from "date-fns/locale/nl";
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router } from 'react-router-dom';
import { App } from './App';
import GlobalCss from './GlobalCss';
import './index.css';
import { syncHistoryWithStore } from './lib/SyncHistoryWithStore';
import * as serviceWorker from './serviceWorker';
import RootStore from './stores/RootStore';
import RouterStore from './stores/RouterStore';

const routerStore = new RouterStore();
const rootStore = new RootStore(routerStore);

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routerStore);

const stores = {
	rootStore,
	routerStore
}

ReactDOM.render(
	<>

		<React.StrictMode>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
				<>
					<GlobalCss />
					<Provider {...stores}>
						<Router history={history}>
							<Route component={App} />
						</Router>
					</Provider>
				</>
			</MuiPickersUtilsProvider>
		</React.StrictMode>
	</>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
