import { DateTime } from 'luxon';
import { action, computed, observable } from 'mobx';


export default class TimeBlockV2 {
	@observable id: string;
	@observable start: DateTime;
	@observable end: DateTime;
	@observable maxNumberOfRegistrations: number = 0;
	@observable registrations: TimeBlockRegistration[] = [];

	@computed get isOccupied() {
		return this.registrations.length >= this.maxNumberOfRegistrations;
	}

	@computed get slotsAvailable() {
		return this.maxNumberOfRegistrations - this.registrations.length;
	}

	@computed get registrationCount() {
		return this.registrations ? this.registrations.length : 0;
	}

	constructor(id: string, start: DateTime, end: DateTime, maxNumberOfRegistrations: number) {
		this.id = id;
		this.start = start;
		this.end = end;
		this.maxNumberOfRegistrations = maxNumberOfRegistrations;
	}

	@action register = (id: string, name: string) => {
		if (this.registrations.length < this.maxNumberOfRegistrations - 1) {
			this.registrations.push(new TimeBlockRegistration(id, name));
		} else {
			throw Error("Time block is full");
		}
	}

	@action updateFromServer = (data: any) => {
		this.start = DateTime.fromISO(data.start);
		this.end = DateTime.fromISO(data.end);
		this.maxNumberOfRegistrations = data.maxNumberOfRegistrations;
		this.registrations.slice(0, this.registrations.length);
		data.registrations.forEach((r: any) => {
			let rg = new TimeBlockRegistration(r.participationId, r.name);
			this.registrations.push(rg);
		});
	}
}

class TimeBlockRegistration {
	@observable id: string | undefined;
	@observable name: string | undefined;

	constructor(participationId: string, name: string) {
		this.id = participationId;
		this.name = name;
	}
}