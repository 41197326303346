import { Box, Container, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import SignInForm from '../../components/SignInForm';
import RootStore from '../../stores/RootStore';


const styles = (theme: Theme): any => createStyles({
	signinSection: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10)
	},
	field: {
		marginBottom: theme.spacing(1)
	},
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
}


@inject('rootStore')
@observer
class SignIn extends React.Component<IProps, {}> {

	@action private onSignIn = async () => {
		//this.props.rootStore!.routerStore.push('/');
	}

	render() {
		const { classes } = this.props;
		return (
			<>
				<Box className={`page-content`}>
					<Box className={`${classes.signinSection}`}>
						<Container maxWidth="md">
							<SignInForm />
						</Container>
					</Box>
				</Box>
			</>
		);
	}
}

export default withStyles(styles)(SignIn);
