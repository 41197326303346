import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import RootStore from '../../../stores/RootStore';
import RefreshIcon from '@material-ui/icons/Refresh';
import Loading from '../../../components/Loading';
import pMinDelay from 'p-min-delay';
import { DateTime } from 'luxon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Divider } from '@material-ui/core';
import EventRegistrationDayTimeBlock from './EventRegistrationDayTimeBlock';
import PublicTimeBlockV2 from '../../../models/PublicTimeBlockV2';
import clsx from 'clsx';


const styles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    selected: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
   }
}));

interface IProps {
    rootStore?: RootStore;
    eventId: string;
}

export default withTheme(inject('rootStore')(observer((props: IProps) => {
    
    const classes = styles();
    const store = props.rootStore!.publicStore;
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState<string | false>(false);

    const loadDays = async () => {
        setIsLoading(true);
        await pMinDelay(store.loadEvent(props.eventId), 1000);
        setIsLoading(false);
    }

    const handleRefresh = async () => {
        await loadDays();
    }

    const handleAccordionClick = (id: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? id : false);
    };

    const handleSelectTimeBlock = (tb: PublicTimeBlockV2) => () => {
        // cancellation timeblock or not occupied
        if (!tb.id || !tb.isOccupied) 
            store.selectTimeBlock(tb);
    }

    return (
        <>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="h5" gutterBottom>
                        Kies een tijdsblok
                    </Typography>
                    <Typography paragraph>
                        Selecteer bij een van onderstaande dagen één tijdsblok en klik vervolgens op <strong>Aanmelden</strong>.
                    </Typography>
                    <Typography>
                        Let op! Je aanmelding is definitief en kan niet meer gewijzigd worden.
                    </Typography>
                </Box>
                <Box>
                    {/* <AddIcon fontSize="large" className="pointer" onClick={handleAdd} />*/}
                    <RefreshIcon fontSize="large" className="pointer" onClick={handleRefresh} />
                </Box>
            </Box>
            <Box>
                <Loading loading={isLoading} text="Tijdsblokken worden geladen...">
                    
                    <Accordion 
                        onClick={handleSelectTimeBlock(new PublicTimeBlockV2())} 
                        expanded={false}
                        className={clsx(store.selectedTimeBlock && !store.selectedTimeBlock!.id && classes.selected)}>
                        <AccordionSummary>
                            <Typography className={classes.heading}>Ik kan helaas niet komen</Typography>
                        </AccordionSummary>
                    </Accordion>

                    {store.event && store.event!.days.map(day => (
                        <Accordion key={day.id} expanded={expanded === day.id} onChange={handleAccordionClick(day.id)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Box display="flex" flexDirection="row" flexWrap="wrap" flexGrow={1} alignItems="center">
                                    <Box flexBasis='33%' minWidth={200}>
                                        <Typography className={classes.heading}>{day.date.toLocaleString(DateTime.DATE_HUGE)}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className={classes.secondaryHeading}>{day.slotsAvailable} van de {day.slotCount} plekken beschikbaar</Typography>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box flexGrow={1}>
                                    {day.timeBlocks.map(tb => (
                                        <EventRegistrationDayTimeBlock key={tb.id} timeBlock={tb} selected={store.selectedTimeBlock && store.selectedTimeBlock!.id === tb.id} onClick={handleSelectTimeBlock(tb)} />
                                    ))}
                                </Box>
                            </AccordionDetails>
                            <Divider />
                        </Accordion>
                    ))}
                </Loading>
            </Box>
        </>
    );
})));


