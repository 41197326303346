import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles, useTheme, withTheme } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, useMediaQuery } from '@material-ui/core';
import RootStore from '../../../stores/RootStore';
import emailValidator from 'email-validator';
import passwordValidator from '../../../lib/PasswordValidator';
import { UserWithSameEmailAlreadyExistsError } from '../../../lib/Errors';

type StateType = 'signin' | 'register' | 'register-success' | 'resetpassword' | 'resetpassword-emailsent';

interface IRegisterValues {
    email: string | undefined,
    name: string | undefined,
    password1: string | undefined,
    password2: string | undefined
}

interface IRegisterValidations {
    email: boolean | undefined,
    name: boolean | undefined,
    password1: boolean | undefined,
    password2: boolean | undefined
}

const useStyles = makeStyles((theme) => ({
    caption: {
        fontWeight: 'bold',
        marginTop: theme.spacing(2)
    },
    textField: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2) 
    },
}))

interface IProps {
    rootStore?: RootStore;
    open: boolean,
    onClose: () => void;
}

export default withTheme(inject('rootStore')(observer((props: IProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [state, setState] = useState<StateType>('signin');
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [signInFailed, setSignInFailed] = useState<boolean>();
    const [emailValid, setEmailValid] = useState<boolean>();
    const [passwordValid, setPasswordValid] = useState<boolean>();
    const [registrationFailed, setRegistrationFailed] = useState<boolean>();
    const [registrationFailedMessage, setRegistrationFailedMessage] = useState<string>();
    const [registerValues, setRegisterValues] = useState<IRegisterValues>({
        email: undefined,
        name: undefined,
        password1: undefined,
        password2: undefined
    });
    const [registerValidations, setRegisterValidations] = useState<IRegisterValidations>({
        email: undefined,
        name: undefined,
        password1: undefined,
        password2: undefined
    });


    useEffect(() => {
        if (email) {
            setEmailValid(emailValidator.validate(email!));
        } else {
            setEmailValid(undefined);
        }        
    }, [email])

    useEffect(() => {
        if (password) {
            setPasswordValid(passwordValidator.validate(password) as boolean);
        } else {
            setPasswordValid(undefined);
        }
    }, [password])

    const close = () => {
        clear();
        props.onClose();
    }

    const clear = () => {
        setEmail(undefined);
        setPassword(undefined);
        setSignInFailed(undefined);
        setRegisterValues({
            email: undefined,
            name: undefined,
            password1: undefined,
            password2: undefined
        });
        setRegisterValidations({
            email: undefined,
            name: undefined,
            password1: undefined,
            password2: undefined
        });
        setRegistrationFailed(undefined);
        setRegistrationFailedMessage(undefined);
    }

    const onChangeRegisterValues = (prop: keyof IRegisterValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegisterValues({...registerValues, [prop]: event.target.value});
    }

    const handleSignIn = async () => {
        if (email && password) {
            var success = await props.rootStore!.authStore.signIn(email!, password!);
            if (success) {
                close();
            } else {
                setSignInFailed(true);
            }
        }
    }

    const handleRegister = async () => {
        var email = registerValues.email !== undefined && emailValidator.validate(registerValues.email);
        var name = registerValues.name != undefined && registerValues.name.length > 0;
        var password1 = registerValues.password1 !== undefined && (passwordValidator.validate(registerValues.password1) as boolean);
        var password2 = registerValues.password2 !== undefined && registerValues.password1 == registerValues.password2;

        setRegisterValidations({...registerValidations, email, name, password1, password2});

        if (email && name && password1 && password2) {
            try {
                await props.rootStore!.authStore.register(registerValues.name!, registerValues.email!, registerValues.password1!);
                await props.rootStore!.authStore.signIn(registerValues.email!, registerValues.password1!);
                setState('register-success');
            } catch (err) {
                if (err instanceof UserWithSameEmailAlreadyExistsError) {
                    setRegistrationFailed(true);
                    setRegistrationFailedMessage(`Email adres is al in gebruik. Gebruik de optie 'wachtwoord vergeten' als je je wachtwoord niet meer weet.`);
                } else {
                    setRegistrationFailedMessage(`Er is iets fout gegaan. Probeer het later nogmaals.`);
                }                
            }            
        }
    }


    const handleCancelSignIn = () => {
        close();
    }

    const backToSignIn = () => {
        setState('signin');
    }

    const handleResetPassword = async () => {
        if (email) {
            await props.rootStore!.authStore.transportLayer.requestPasswordReset(email!);
		    setState('resetpassword-emailsent');
        }
    }

    const showRegistrationForm = () => {
        setState('register');
    }

    return (
        <Dialog 
            open={props.open}
            fullScreen={fullScreen}
            maxWidth="sm"
            fullWidth={true}
            scroll="paper">
            <DialogTitle>
                {state === 'signin' && 'Inloggen'}
                {(state === 'register' || state === 'register-success') && 'Registreren'}
                {(state === 'resetpassword' || state === 'resetpassword-emailsent') && 'Wachtwoord opnieuw instellen'}
            </DialogTitle>
            
            {state === 'signin' &&
                <>
                    {signInFailed &&                     
                        <Box bgcolor="error.main" color="white" p={2}>
                            <Typography>Inloggen mislukt. Controleer email en wachtwoord en probeer het opnieuw.</Typography>
                        </Box>                     
                    }
                    <DialogContent>
                        <TextField
                            id="email"
                            label="Email"
                            required
                            className={classes.textField}
                            onChange={e => setEmail(e.target.value)}
                            error={emailValid !== undefined && !emailValid}
                        />
                        <TextField
                            id="password"
                            label="Wachtwoord"
                            type="password"
                            required
                            className={classes.textField}
                            onChange={e => setPassword(e.target.value)}
                            error={passwordValid !== undefined && !passwordValid}
                            fullWidth />
                            <Box display="flex" justifyContent="space-between">
                                <Button variant="text" color="primary" size="small" style={{textTransform: 'none'}} onClick={() => setState('resetpassword')}>Wachtwoord vergeten?</Button>
                                <Button variant="text" color="primary" size="small" style={{textTransform: 'none'}} onClick={showRegistrationForm}>Ik ben nieuw en wil mij registreren</Button>
                            </Box>                            
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="primary" onClick={handleCancelSignIn}>Annuleren</Button>
                        <Button variant="contained" color="primary" onClick={handleSignIn}>Inloggen</Button>
                    </DialogActions>                        
                </>
            }
            {state === 'resetpassword' && 
                <>
                    <DialogContent>
                        <Typography>
                            Vul je email adres in. We sturen je een email om je wachtwoord opnieuw in te stellen.
                        </Typography>
                        <TextField
							id="email"
							label="Email"
                            className={classes.textField}
                            defaultValue={email}
							onChange={e => setEmail(e.target.value)}
							fullWidth
							required />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="primary" onClick={backToSignIn}>Terug naar inloggen</Button>
                        <Button color="primary" variant="contained" onClick={handleResetPassword}>Verstuur email</Button>
                    </DialogActions>
                </>
            }
            {state === 'resetpassword-emailsent' && 
                <>
                    <DialogContent>
                        <Typography>
                            We hebben je een email verzonden om je wachtwoord opnieuw in te stellen. Volg de instructies in de email en log daarna in.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={() => setState('signin')}>OK</Button>
                    </DialogActions>
                </>
            }
            {state === 'register' &&
                <>
                    {registrationFailed &&
                        <Box bgcolor="error.main" color="white" p={2}>
                            <Typography>{registrationFailedMessage}</Typography>
                        </Box>                     
                    }
                    <DialogContent>
                        <Typography style={{ marginTop: '20px', marginBottom: '20px' }}>
                            Voer onderstaande gegevens in om te registreren.
                        </Typography>
                        <TextField
                            id="registernaam"
                            label="Naam"
                            autoComplete="off"
                            onChange={onChangeRegisterValues('name')}
                            className={classes.textField}
                            error={registerValidations.name !== undefined && !registerValidations.name}
                            fullWidth
                            required
                        />
                        <TextField
                            id="registeremail"
                            label="Email"
                            autoComplete="off"
                            onChange={onChangeRegisterValues('email')}
                            className={classes.textField}
                            error={registerValidations.email !== undefined && !registerValidations.email}
                            fullWidth
                            required
                        />
                        <TextField
                            id="registerpassword"
                            label="Wachtwoord"
                            type="password"
                            autoComplete="off"
                            onChange={onChangeRegisterValues('password1')}
                            className={classes.textField}
                            error={registerValidations.password1 !== undefined && !registerValidations.password1}
                            helperText={registerValidations.password1 !== undefined && !registerValidations.password1 ? 'Minimaal 8 tekens lang, maximaal 30 tekens lang, tenminste 1 hoofdletter, 1 kleine letter en een cijfer.' : ''}
                            fullWidth
                            required />
                        <TextField
                            id="registerpassword-check"
                            label="Wachtwoord nogmaals"
                            type="password"
                            autoComplete="off"
                            onChange={onChangeRegisterValues('password2')}
                            className={classes.textField}
                            error={registerValidations.password2 !== undefined && !registerValidations.password2}
                            fullWidth
                            required />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="primary" onClick={backToSignIn}>Terug naar inloggen</Button>
                        <Button variant="contained" color="primary" onClick={handleRegister}>Registreren</Button>
                    </DialogActions>
                </>
            }
            {state === 'register-success' && 
                <>
                    <DialogContent>
                        <Typography>
                            Je bent successvol geregistreerd en direct ingelogd. Klik op OK om door te gaan.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={close}>OK</Button>
                    </DialogActions>
                </>
            }   
        </Dialog>
    );
})));