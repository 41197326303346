import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { computed } from "mobx";
import { inject, observer } from "mobx-react";
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedArea from '../../components/AuthenticatedArea';
import RootStore from '../../stores/RootStore';
import MyEventManagement from './MyEventManagement';
import MyEvents from './MyEvents';
import { EventLoader } from '../../components/loaders/EventLoader'

const styles = (theme: Theme): any => createStyles({
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
	eventId: string
}


@inject('rootStore')
@observer
class MyIndex extends React.Component<IProps, {}> {

	@computed private get areEventsLoaded() {
		return false;
	}

	render() {
		return (
			<>
				<AuthenticatedArea>
					<Switch>
						<Route exact={true} path="/my/events" component={MyEvents} />
						<Route exact={true} path="/my/events/:eventId" render={props =>
							<EventLoader eventId={props.match.params.eventId} loadDays={true} loadRegistrations={true}>
								<MyEventManagement {...props} eventId={props.match.params.eventId} />
							</EventLoader>
						}/>
					</Switch>
				</AuthenticatedArea>
			</>
		);
	}
}

export default withStyles(styles)(MyIndex);