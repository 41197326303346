import { action, observable } from "mobx";
import { v4 as uuidv4 } from 'uuid';


export default class User {
	constructor(id = uuidv4()) {
		this.id = id;
	}

	@observable id: string | undefined = undefined;
	@observable username: string | undefined = '';
	@observable name: string | undefined = '';

	@action updateFromServer = (data: any) => {
		this.id = data.id;
		this.username = data.username;
		this.name = data.name;
	}
}