import { action, computed, observable } from 'mobx';
import EventDayStore from '../stores/EventDayStore';
import TimeBlockV2 from './TimeBlockV2';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

export interface IEventDayConfiguration {
	date:DateTime;
	start:DateTime | undefined;
	end:DateTime | undefined;
	timeBlockDuration:number | undefined;
	timeBlockMeantimeDuration:number | undefined;
	timeBlockCount:number | undefined;
	simultaneGroupCount:number | undefined;
	totalGroupCount:number | undefined;
	timeBlockDurationMode: 'calculated' | 'fixed' | undefined;
	timeBlockCountMode: 'total-groups' | 'fixed' | undefined;
}

class EventDayConfiguration implements IEventDayConfiguration{
	@observable date:DateTime = DateTime.invalid(' ');
	@observable start:DateTime | undefined = undefined;
	@observable end:DateTime | undefined = undefined;
	@observable timeBlockDuration:number | undefined = undefined;
	@observable timeBlockMeantimeDuration:number | undefined = undefined;
	@observable timeBlockCount:number | undefined = undefined;
	@observable simultaneGroupCount:number | undefined = undefined;
	@observable totalGroupCount:number | undefined = undefined;
	@observable timeBlockDurationMode: 'calculated' | 'fixed' | undefined = undefined;
	@observable timeBlockCountMode: 'total-groups' | 'fixed' | undefined = undefined;
}


export default class EventDay {
	@observable id: string;
	@observable isNew: boolean;
	@observable store: EventDayStore;
	@observable eventId: string;
	@observable configuration: EventDayConfiguration = new EventDayConfiguration();
	@observable date: DateTime;
	@observable timeZone: string = 'Europe/Amsterdam';
	@observable timeBlocks: TimeBlockV2[] = [];
	@observable state: 'active'|'inactive' = 'inactive';

	@computed get isOccupied(): boolean {
		return _.sumBy(this.timeBlocks, tb => tb.slotsAvailable) === 0;
	}

	@computed get slotCount(): number {
		return _.sumBy(this.timeBlocks, tb => tb.maxNumberOfRegistrations);
	}

	@computed get registrationCount() : number {
		return _.sumBy(this.timeBlocks, tb => tb.registrations.length)
	}

	@computed get slotsAvailable() : number {
		return this.slotCount - this.registrationCount;
	}

	constructor(store: EventDayStore, eventId: string, date: DateTime, id: string | undefined = undefined) {
		this.store = store
		this.isNew = id === undefined;
		this.id = id ? id : uuidv4();		
		this.eventId = eventId;
		this.date = date.set({
			hour: 0,
			minute: 0,
			second: 0,
			millisecond: 0
		});
	}

	@action activate = async () => {
		if (this.state !== 'active') this.state = 'active';
		await this.store.transportLayer.activateEventDay(this.eventId, this.id);
	}

	@action configure = (config: IEventDayConfiguration) => {
		if (this.state === 'active') throw Error('Configuration of an active day cannot be updated');
		this.date = config.date;
		this.configuration = config;
		this.timeBlocks = EventDay.createTimeBlocks(config);
	}

	@action updateFromServer = (data: any) => {
		this.id = data._id;
		this.isNew = data._id === undefined;
		this.date = DateTime.fromISO(data.date, { zone: data.timeZone });
		this.timeZone = data.timeZone;
		this.eventId = data.eventId;
		this.state = data.state;
		this.configuration.date = DateTime.fromISO(data.configuration.date, { zone: data.timeZone });
		this.configuration.start = DateTime.fromISO(data.configuration.start, { zone: data.timeZone });
		this.configuration.end = DateTime.fromISO(data.configuration.end, { zone: data.timeZone });
		this.configuration.simultaneGroupCount = data.configuration.simultaneGroupCount;
		this.configuration.totalGroupCount = data.configuration.totalGroupCount;
		this.configuration.timeBlockCount = data.configuration.timeBlockCount;
		this.configuration.timeBlockDurationMode = data.configuration.timeBlockDurationMode;
		this.configuration.timeBlockCountMode = data.configuration.timeBlockCountMode;
		this.configuration.timeBlockDuration = data.configuration.timeBlockDuration;
		this.configuration.timeBlockMeantimeDuration = data.configuration.timeBlockMeantimeDuration;
		data.timeBlocks.forEach((d:any) => {
			let tb = new TimeBlockV2(d.id, DateTime.fromISO(d.start), DateTime.fromISO(d.end), d.maxNumberOfRegistrations);
			tb.updateFromServer(d);
			this.timeBlocks.push(tb); 
		});
	}

	save = async () => {
		await this.store.transportLayer.saveEventDay(this);
		this.isNew = false;
		this.store.sortDays();
	}

	static createTimeBlocks = (config: IEventDayConfiguration) : TimeBlockV2[] => {
		if (config.start === undefined || !config.start.isValid 
			|| config.end === undefined|| !config.end.isValid 
			|| config.timeBlockCountMode == undefined
			|| config.timeBlockDurationMode === undefined
			|| config.timeBlockCount === undefined || config.timeBlockCount <= 0
			|| config.timeBlockDuration === undefined || config.timeBlockDuration <= 0
			|| config.timeBlockMeantimeDuration === undefined || config.timeBlockMeantimeDuration < 0
			|| config.simultaneGroupCount === undefined || config.simultaneGroupCount <= 0) 
			return [];

		// create timeblocks according to configuration.	
		let start = config.date.set({
			hour: config.start!.hour,
			minute: config.start!.minute,
			second: config.start!.second
		});

		let end: DateTime | undefined = undefined;
		let timeBlocks: TimeBlockV2[] = [];
		for (let i = 0; i < config.timeBlockCount!; i++) {
			start = end ? end.plus({ minutes: config.timeBlockMeantimeDuration }) : start;
			end = start.plus({ minutes: config.timeBlockDuration });
			
			let timeBlock = new TimeBlockV2(uuidv4(), start, end, config.simultaneGroupCount!);
			timeBlocks.push(timeBlock);			
		}

		return timeBlocks;
	}
}