import { Box, Container, createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import RootStore from '../../stores/RootStore';


const styles = (theme: Theme): any => createStyles({
	signinSection: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10)
	},
	field: {
		marginBottom: theme.spacing(1)
	},
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
}


@inject('rootStore')
@observer
class TermsOfUse extends React.Component<IProps, {}> {


	render() {
		//const { classes } = this.props;
		return (
			<>
				<Box className={`page-content`}>
					<Container maxWidth="md">
						<Box className="page-title">
							<Typography variant="h4">Gebruiksvoorwaarden</Typography>
						</Box>
					</Container>
				</Box>
				<Box className={`section`}>
					<Container maxWidth="md">
						<Typography variant="h5">Introductie</Typography>
						<Typography paragraph={true}>
							In deze Gebruiksvoorwaarden worden de voorwaarden besproken waaronder u als Gebruiker van Tijdprikker.nl gebruik kunt maken van
							de Dienst die aangeboden wordt op Tijdprikker.nl. Lees deze Gebruiksvoorwaarden goed door, zodat u weet wat
							uw rechten en plichten zijn wanneer u gebruik maakt van de Dienst. Door gebruik te maken van de Dienst, op welke manier dan
							ook, aanvaardt u daarmee gebonden te zijn aan deze Algemene voorwaarden.
						</Typography>
						<Typography variant="h5">Definities</Typography>
						<Typography paragraph={true}>
							"Tijdprikker.nl", "we", "wij", "ons", en "onze" betekenen "Tijdprikker.nl". "Tijdprikker.nl" is een initiatief dat mensen de
							mogelijkheid geeft bijeenkomsten te organiseren waarbij de genodigden verdeeld worden over tijdsblokken.
						</Typography>
						<Typography paragraph={true}>
							"Service", "services", "dienst", "diensten", "platform", en "product" verwijzen naar de (mobile) website en mobile phone app waardoor onze services
							beschikbaar gemaakt worden.
						</Typography>

						<Typography variant="h5">Omvang en aard van onze dienstverlening</Typography>
						<Typography paragraph={true}>
							Via ons platform, bieden wij een mogelijkheid aan waarmee gebruikers online een bijeenskomst kunnen plannen en online kunnen communiceren
							met andere gebruikers.
						</Typography>
						<Typography paragraph={true}>
							Tenzij anders vermeld, mogen onze diensten alleen gebruikt worden door personen van 18 jaar en ouder. Personen van 17 jaar en
							jonger mogen onze diensten alleen gebruiken onder toezicht van ouders en/of voogd.
						</Typography>

						<Typography variant="h5">Privacy en Cookies</Typography>
						<Typography paragraph={true}>
							Tijdprikker.nl respecteert uw privacy. Bekijk ons Privacy Statement voor meer informatie.
						</Typography>

						<Typography variant="h5">Gratis voor consumenten</Typography>
						<Typography paragraph={true}>
							Tenzij anders aangegeven of anderszins redelijk kan worden geacht, zijn onze diensten gratis. { /*Onze omzet komt voort uit advertenties, betaalde versies en onze ppartner platformen.*/}
						</Typography>
						<Typography paragraph={true}>
							De term "gratis" is alleen van toepassing op de diensten geleverd door Tijdprikker.nl.
						</Typography>

						<Typography variant="h5">Correspondentie en communicatie</Typography>
						<Typography paragraph={true}>
							Door gebruik te maken van ons platform ga je akkoord met het ontvangen van emails die wij mogelijk verzenden betreffende uw event.
							De communicatie kan zowel functioneel als informatief van aard zijn. Deze emails kunnen ook commerciele utingen bevatten.
						</Typography>
						<Typography paragraph={true}>
							Je gaat ook akkoord dat elke persoon die je uitnodigt voor een event, rederlijkerwijs een gewillige deelnemer is aan je event of iemand
							die je (indirect) kent. Je gaat er mee akkoord dat alle informatie die je indient correct is en dat je de rechten bezit op alle inhoud die je
							uploadt naar onze platformen.
						</Typography>

						<Typography variant="h5">Misbruik</Typography>
						<Typography paragraph={true}>
							Onwettig gebruik van ons platform is verboden. We nemen contact op met de autoriteiten wanneer evenementen illegale inhoud bevatten (zoals,
							maar niet beberkt tot: aanzetten tot haat, pornografie door minderjarigen, enz.), Of wanneer ons platform wordt gebruikt voor verzending van spam.
							Op het gebruik van ons platform is Nederlands en tot op zekere hoogte Europees recht van toepassing.
						</Typography>

						<Typography variant="h5">Intellectueel eigendom</Typography>
						<Typography paragraph={true}>
							Tenzij anders aangegeven, is de software vereist voor onze diensten, of gebruikt door ons platform, eigendom van Tijdprikker.nl.
						</Typography>

						<Typography variant="h5">Disclaimer</Typography>
						<Typography paragraph={true}>
							Houd er rekening mee dat gegevens in een evenement doorgaans zichtbaar zijn voor alle deelnemers. Verstrek geen geheimen of persoonlijke gegevens.
							We passen verschillende beveiligingsmechanismen toe om een veilige omgeving te bieden, maar ons platform is niet bedoeld om gevoelige of persoonlijke
							gegevens te delen.
						</Typography>

						<Typography variant="h5">Eigendom van gegevens</Typography>
						<Typography paragraph={true}>
							Iedereen is verantwoordelijk voor, en heeft recht op, zijn/haar gegevens. Bij twijfel zijn de evenementgegevens eigendom van de organisator.
						</Typography>

						<Typography variant="h5">Diversen</Typography>
						<Typography paragraph={true}>
							Voor zover wettelijk toegestaan, worden deze voorwaarden beheerst door en geïnterpreteerd in overeenstemming met Nederlands recht en elk geschil dat
							voortvloeit uit deze algemene voorwaarden en onze diensten worden uitsluitend voorgelegd aan de bevoegde rechtbanken in Nederland.
						</Typography>
					</Container>
				</Box>
			</>
		);
	}
}

export default withStyles(styles)(TermsOfUse);
