import { action, observable } from 'mobx';
import EventCapacityStore from "../stores/EventCapacityStore";
import TimeBlock from './TimeBlock';

export default class EventCapacity {
	store: EventCapacityStore;
	eventId: string;
	@observable timeBlocks: TimeBlock[] = [];
	@observable cancellations: string[] = [];

	constructor(store: EventCapacityStore, eventId: string) {
		this.store = store
		this.eventId = eventId;
	}

	@action updateFromServer = (data: any) => {
		this.eventId = data._id;
		data.timeBlocks.slice(0, data.timeBlocks.length);
		data.timeBlocks.forEach((tb: any) => {
			let timeBlock = new TimeBlock(tb.id, tb.start, tb.end, tb.maxnumberOfRegistrations);
			timeBlock.updateFromServer(tb);
			this.timeBlocks.push(timeBlock);
		});
		if (data.cancellations) {
			data.cancellations.forEach((c: any) => {
				this.cancellations.push(c);
			});
		}
	}
}