import DateFnsUtils from '@date-io/date-fns';
import nlLocale from "date-fns/locale/nl";
import { DateTime } from 'luxon';

const dateUtils: DateFnsUtils = new DateFnsUtils({ locale: nlLocale });
export default dateUtils;

let toDateString = (date: Date | undefined) => {
	if (!date) return '';
	return dateUtils.format(date!, 'PPPP');
}

let toTimeString = (time: Date | undefined, emptyFormat:string = '__:__') => {
	if (!time || isNaN(time.getTime())) return emptyFormat;
	
	return dateUtils.format(time!, 'HH:mm');
}


export class DateUtils {
	static dateOnly = (dateTime: DateTime) : DateTime => {
		return dateTime.set({
			hour: 0,
			minute: 0,
			second: 0,
			millisecond: 0
		});
	}

	static toDateString = (date: DateTime | undefined, emptyFormat:string = '...') => {
		if (date && date.isValid) return date.toLocaleString(DateTime.DATE_HUGE);		
		return emptyFormat;
	}

	static toTimeString = (time: DateTime | undefined, emptyFormat:string = '__:__') => {
		if (time && time.isValid) return time.toLocaleString(DateTime.TIME_24_SIMPLE);
		return emptyFormat;
	}
}

export { toDateString, toTimeString };

