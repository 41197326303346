import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Typography, withTheme, Paper } from '@material-ui/core';
import EventDay, { IEventDayConfiguration } from '../models/EventDay';
import { DateUtils } from '../lib/DateUtils';
import TimeBlockV2 from '../models/TimeBlockV2';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme) => ({
    timeBlock: {
        marginTop: theme.spacing(1),
        maringBottom: theme.spacing(1),
        '&:not(:last-child)': {
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            borderColor: theme.palette.divider
        }
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    }
}))

interface IProps {
    eventDayConfiguration: IEventDayConfiguration
}

const TimeBlocksPreview = withTheme((props: IProps) => {
    const classes = useStyles();
    const [timeBlocks, setTimeBlocks] = useState<TimeBlockV2[]>([]);
    
    useEffect(() => {
        if (props.eventDayConfiguration) {
            setTimeBlocks(EventDay.createTimeBlocks(props.eventDayConfiguration));    
        } else {
            setTimeBlocks([]);
        }        
    }, [props.eventDayConfiguration])

    return (
        <Box>
            {timeBlocks.map(tb =>                
                <Box key={tb.id} display="flex" flexDirection="row" className={classes.timeBlock} p={1}>
                    <Box flexBasis='50%' minWidth={150}>
                        <Typography>
                            {DateUtils.toTimeString(tb.start)} - {DateUtils.toTimeString(tb.end)}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography className={classes.secondaryHeading}>{tb.slotsAvailable} plek{tb.slotsAvailable > 1 ? 'ken': ''} beschikbaar</Typography>                        
                    </Box>
                </Box>                
            )}
        </Box>
    );
});

export { TimeBlocksPreview };





