import React, { useEffect, useState } from 'react';

type WindowSize = {
    width: number | undefined,
    height: number | undefined
}

const useWindowSize = (callback: (windowSize: WindowSize) => void) => {
    
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        callback(windowSize);
    }, [windowSize])

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        
        // Add event listener
        window.addEventListener("resize", handleResize);
        
        // Call handler right away so state gets updated with initial window size
        handleResize();
        
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);

    }, []);

    return windowSize;
}

export { useWindowSize }
