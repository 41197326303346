import _, { Dictionary } from 'lodash';
import { action, computed, observable } from 'mobx';
import Event from '../models/Event';
import { Registration } from '../models/Registration';
import RootStore from './RootStore';
import TransportLayer from './TransportLayer';

export class RegistrationStore {
	transportLayer: TransportLayer;
	@observable registrations: Registration[] = [];
	rootStore: RootStore;

	constructor(rootStore: RootStore, transportLayer: TransportLayer) {
		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	@computed get registrationsByEventDay() : Dictionary<Registration[]> {
		return _.groupBy(this.registrations, 'eventDayId');
	}

	@computed get registrationsByTimeBlock() : Dictionary<Registration[]> {
		return _.groupBy(this.registrations, 'timeBlockId');
	}

	@action loadForEvent = async (event: Event) => {
		this.registrations = [];
		let regs = await this.transportLayer.fetchRegistrations(event.id!);
        if (regs) {
            regs.forEach((data: any) => {
                let reg = new Registration(this);
                reg.updateFromServer(data);
                this.registrations.push(reg);
            });
        }
	}
}
