import { action, observable } from 'mobx';
import TransportLayer from './TransportLayer';
import PublicEvent from '../models/PublicEvent';
import PublicEventDay from '../models/PublicEventDay';
import PublicTimeBlockV2 from '../models/PublicTimeBlockV2';

export default class PublicStore {
	transportLayer: TransportLayer;
	@observable isLoading: boolean = false;
	@observable event: PublicEvent | undefined;
	@observable selectedTimeBlock: PublicTimeBlockV2 | undefined;

	constructor(transportLayer: TransportLayer) {
		this.transportLayer = transportLayer;
	}

	@action loadEvent = async (eventId: string) => {
		this.isLoading = true;
		this.event = undefined;
		
		let fe = await this.transportLayer.fetchPublicEvent(eventId)
		let event = new PublicEvent(this);
		event.updateFromServer(fe);
		this.event = event;
				
		this.isLoading = false;
	}

	@action selectTimeBlock = (tb: PublicTimeBlockV2 | undefined ) => {
		this.selectedTimeBlock = tb;
	}
}