import { LocalStorage } from "../lib/LocalStorage";


class CookiePreferences {

    functional: boolean | undefined;
    analytical: boolean | undefined;
    optimization: boolean | undefined;
    advertisement: boolean | undefined;

    load = () : boolean => {
        try {
            const val = LocalStorage.getItem<CookiePreferences>('tijdprikker_cookie_preferences');
            if (val) {
                this.functional = val.functional;
                this.analytical = val.analytical;
                this.optimization = val.optimization;
                this.advertisement = val.advertisement;
            }
            return val !== undefined;
        } catch (err) {
            return false;
        }
    }

    acceptAllAndSave = () => {
        this.functional = this.analytical = this.optimization = this.advertisement = true;
        this.save();
    }

    save = () => {
        LocalStorage.setItem('tijdprikker_cookie_preferences', this, 1000 * 60 * 60 * 24 * 365);
    }
}

export { CookiePreferences }