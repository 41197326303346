import { action, computed, observable } from 'mobx';
import Event from '../models/Event';
import RootStore from './RootStore';
import TransportLayer from './TransportLayer';

export default class EventStore {
	transportLayer: TransportLayer;
	@observable events: Event[] = [];
	rootStore: RootStore;

	@computed get organizedEvents() {
		if (!this.rootStore.authStore.isAuthenticated) return [];
		return this.events.filter(e => e.ownerUserId === this.rootStore.authStore.user!.id);
	}

	constructor(rootStore: RootStore, transportLayer: TransportLayer) {
		this.transportLayer = transportLayer;
		this.rootStore = rootStore;
	}

	@action clear = async () => {
		this.events = [];
	}

	@action createEvent = (): Event => {
		let event = new Event(this);
		if (this.rootStore.authStore.isAuthenticated) {
			event.ownerUserId = this.rootStore.authStore.user!.id;
			event.setOrganizerEmail(this.rootStore.authStore.user!.username!);
			event.setOrganizerName(this.rootStore.authStore.user!.name!);
		}
		this.events.push(event);
		return event;
	}

	/// Load event
	@action loadEvent = async (eventId: string) => {
		let e = await this.transportLayer.fetchEvent(eventId);
		let event = this.get(eventId);
		if (event) {
			event.updateFromServer(e);
		} else {
			// create event and push to memcache.
			event = new Event(this);
			event.updateFromServer(e);
			this.events.push(event);
		}
		this.sort();
	}

	/// reload organized events for user.
	@action loadOrganizedEventsForUser = async (userId: string) => {
		let retrievedEvents = await this.transportLayer.fetchOrganizedEventsForUser(userId);
		retrievedEvents.forEach((e: any, i: number) => {
			let event = this.get(e._id);
			if (event) {
				event.updateFromServer(e);
			} else {
				event = new Event(this);
				event.updateFromServer(e);
				this.events.push(event);
			}
		});
		this.sort();
	}

	get = (eventId: string): Event | undefined => {
		return this.events.find(e => e.id === eventId);
	}

	@action private sort = () => {
		this.events = this.events.slice().sort((a, b) => {
			a.title?.localeCompare(b.title ? b.title : '');
			// if (a.start === b.start) return 0;
			// if (a.start && b.start) {
			// 	if (a.start < b.start) return 1;
			// 	if (a.start > b.start) return -1;
			// }
			// if (!a.start && b.start) return 1;
			// if (a.start && !b.start) return -1;

			return 0;
		})
	}
}
