import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import React from "react";

const styles = (theme: Theme): any => createStyles({
	'@global': {
		html: {
			fontFamily: 'Raleway, Arial'
		},
		body: {
			fontFamily: 'Raleway, Arial'
		},
		'.page-content': {
			paddingTop: '64px' // app bar correction
		},
		'.page-title': {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2)
		},
		'.paper-padding': {
			padding: theme.spacing(2)
		},
		'.footer': {
			position: 'absolute',
			bottom: 0,
			width: '100%'
		},
		'.footer-sticky': {
			position: 'fixed',
			bottom: 0,
			width: '100%'
		},
		'.card-info': {
			backgroundColor: blue['100'],
			borderColor: blue['300']
		},
		'.section': {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3)
		},
		'.section-highlight': {
			backgroundColor: grey[200]
		},
		'.pointer': {
			cursor: 'pointer'
		}
	}
});

interface IProps extends WithStyles<typeof styles> {
}

class GlobalCss extends React.Component<IProps, {}> {
	render() {
		return null;
	}
}

export default withStyles(styles)(GlobalCss);

// const GlobalCss = withStyles({
// 	// @global is handled by jss-plugin-global.
// 	'@global': {
// 		// You should target [class*="MuiButton-root"] instead if you nest themes.
// 		'.page-title': {
// 			margin: 10
// 		},
// 	},
// })(() => null);

//export default GlobalCss;