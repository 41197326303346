import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import grey from '@material-ui/core/colors/grey';
import EventDay from '../../../models/EventDay';
import Event from '../../../models/Event';
import { DateTime } from 'luxon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DayRegistrations from './DayRegistrations';
import _ from 'lodash';

const styles = makeStyles((theme) => ({
    day: {
        padding: theme.spacing(1),
        borderStyle: 'solid',
		borderWidth: 1,
        borderRadius: 4,
		borderColor: grey[400]
    },
    marginLeft: {
        marginLeft: theme.spacing(2)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    registrationChip: {
        marginLeft: theme.spacing(1)
    }
}));

interface IProps {
    event: Event;
    onEditEventDay?: (day:EventDay) => void;
}

export default withTheme(observer((props: IProps) => {
    
    const classes = styles();
    const [expanded, setExpanded] = useState<string | false>(false);
    
    const handleAccordionClick = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleEdit = (eventDay: EventDay) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (props.onEditEventDay) props.onEditEventDay(eventDay);
    }

    return (
        <>
            <Box>
                {props.event.days.map(day => (
                    <Accordion key={day.id} expanded={expanded === day.id} onChange={handleAccordionClick(day.id)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Box display="flex" flexDirection="row" flexWrap="wrap" flexGrow={1} alignItems="center">
                                <Box flexBasis='33%' minWidth={200}>
                                    <Typography className={classes.heading}>{day.date.toLocaleString(DateTime.DATE_HUGE)}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.secondaryHeading}>{day.slotsAvailable} van de {day.slotCount} plekken beschikbaar</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" flexWrap="wrap" flexGrow={1} justifyContent="flex-end">
                                    <Box>
                                        {day.state === 'active' &&
                                            <Chip className={classes.marginLeft} label={day.state === 'active' ? 'actief' : 'inactief'} color={day.state === 'active' ? 'primary' : 'default'} size="small" />}
                                        {day.state !== 'active' &&
                                            <Button className={classes.marginLeft} size="small" onClick={handleEdit(day)}>Wijzigen</Button>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DayRegistrations day={day} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </>
    );
}));



