import { Box, Button, Container, createStyles, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { DateTime } from 'luxon';
import { action, observable } from 'mobx';
import { inject, observer } from "mobx-react";
import pMinDelay from 'p-min-delay';
import React from 'react';
import Loading from '../../components/Loading';
import { DateUtils } from '../../lib/DateUtils';
import { TimeBlockOccupiedError } from '../../lib/Errors';
import Participation from '../../models/Participation';
import PublicStore from '../../stores/PublicStore';
import RootStore from '../../stores/RootStore';
import EventRegistrationDays from './components/EventRegistrationDays';

const styles = (theme: Theme): any => createStyles({
	timeBlock: {
		padding: theme.spacing(1),
		backgroundColor: 'white',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRadius: 4,
		borderColor: grey[400]
	},
	selected: {
		color: 'white',
		backgroundColor: theme.palette.primary.main
	},
	occupied: {
		backgroundColor: theme.palette.action.disabled,
		color: theme.palette.action.disabled
	},
	footer: {
		position: 'fixed',
		bottom: 0,
		backgroundColor: theme.palette.background.default,
		boxShadow: '0px -5px 10px rgba(0,0,0,0.30)'
	},
	card: {
		margin: theme.spacing(3)
	},
	field: {
		marginBottom: theme.spacing(1)
	},
});

interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
	eventId: string
}

@inject('rootStore')
@observer
class EventParticipation extends React.Component<IProps, {}> {

	@observable private registrationFinished: boolean = false;
	@observable private isOccupiedDialogOpen: boolean = false;
	@observable private showInputErrors: boolean = false;
	private store: PublicStore;
	private participation: Participation;

	constructor(props: IProps) {
		super(props);
		this.store = props.rootStore!.publicStore;
		this.participation = this.props.rootStore!.participationStore.create(this.props.eventId);
	}

	@action private reserve = () => {
		this.showInputErrors = true;
		if (this.participation.nameValid && this.participation.emailValid) {
			if (this.store.selectedTimeBlock) {
				this.participation.setTimeBlock(this.store.selectedTimeBlock!.id);
			}
			if (this.store.selectedTimeBlock && this.store.selectedTimeBlock.eventDay) {
				this.participation.setEventDay(this.store.selectedTimeBlock!.eventDay!.id);
			}
			this.participation.save().then(() => {
				this.registrationFinished = true;
			}).catch((err: Error) => {
				if (err instanceof TimeBlockOccupiedError) {
					this.isOccupiedDialogOpen = true;
				} else {
					throw err;
				}
			});
		}
	}

	@action occupiedDialogClose = () => {
		this.isOccupiedDialogOpen = false;
		this.store.loadEvent(this.props.eventId);
	}

	@action loadEvent = async (delayed: boolean = false) => {
        await pMinDelay(this.store.loadEvent(this.props.eventId), delayed ? 1000 : 0);
    }

	render() {
		//if (!this.isEventLoaded || !this.isEventCapacityLoaded) return null;

		return (
			<Box className={`page-content`}>
				{!this.registrationFinished && this.renderRegister()}
				{this.registrationFinished && this.renderFinished()}
				{this.renderOccupiedModal()}
			</Box>
		);
	}

	private renderOccupiedModal() {
		return (
			<>
				<Dialog
					open={this.isOccupiedDialogOpen}
					onClose={() => { }}
					aria-labelledby="Helaas, iemand was u net voor."
					aria-describedby="Dit tijdsblok is niet meer beschikbaar.">
					<DialogTitle>
						Helaas, iemand was u net voor.
					</DialogTitle>
					<DialogContent>
						{`Het door jouw geselecteerde tijdsblok ${this.store.selectedTimeBlock && this.store.selectedTimeBlock!.start.toLocaleString(DateTime.DATE_FULL)}, ${this.store.selectedTimeBlock && DateUtils.toTimeString(this.store.selectedTimeBlock!.start)} - ${this.store.selectedTimeBlock && DateUtils.toTimeString(this.store.selectedTimeBlock!.end)}
						is zojuist door iemand anders gereserveerd en niet langer beschikbaar. Probeer het opnieuw.`}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.occupiedDialogClose} color="primary" autoFocus variant="contained">
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}

	private renderWho() {
		const { classes } = this.props;
		return (
			<>
				<Box>
					<TextField
						id="name"
						label="Naam"
						value={this.participation.name}
						onChange={(e) => this.participation.setName(e.target.value)}
						className={classes.field}
						error={this.showInputErrors && !this.participation.nameValid}
						fullWidth
						required
					/>
					<TextField
						id="email"
						label="Email"
						value={this.participation.email}
						onChange={(e) => this.participation.setEmail(e.target.value)}
						className={classes.field}
						error={this.showInputErrors && !this.participation.emailValid}
						onKeyDown={() => { this.showInputErrors = true; }}
						fullWidth
						required
					/>
					<TextField
						id="numberOfAdults"
						label="Aantal volwassenen"
						type="number"
						value={this.participation.numberOfAdults}
						onChange={(e) => this.participation.setNumberOfAdults(+e.target.value)}
						className={classes.field}
						fullWidth
						required
					/>
					<TextField
						id="numberOfChildren"
						label="Aantal kinderen"
						type="number"
						value={this.participation.numberOfChildren}
						onChange={(e) => this.participation.setNumberOfChildren(+e.target.value)}
						className={classes.field}
						fullWidth
						required
					/>
				</Box>
			</>
		)
	}

	private renderRegister(): JSX.Element {
		const { classes } = this.props;
		return (
			<>
				<Box>
					<Container maxWidth="md">
						<Box className="page-title">
							<Typography variant="h4">Uitnodiging</Typography>
						</Box>
					</Container>
				</Box>
				<Box className={`section section-highlight`}>
					<Container maxWidth="md">
						<Loading loading={this.store.isLoading || !this.store.event}>
							<Typography variant="h6">
								{this.store.event ? this.store.event!.title : ''}
							</Typography>
							<Typography variant="subtitle1">
								{this.store.event ? this.store.event!.description : ''}
							</Typography>
							<Typography>
								Locatie: {this.store.event ? this.store.event!.location : ''}
							</Typography>
						</Loading>
					</Container>
				</Box>
				<Box className={`section`}>
					<Container maxWidth="md">
						{this.renderWho()}
					</Container>
				</Box>
				<Box className={`section section-highlight`}>
					<Container maxWidth="md" style={{ paddingBottom: '90px' }}>
						<EventRegistrationDays eventId={this.props.eventId} />
					</Container>
				</Box>

				{this.store.selectedTimeBlock && (
					<Box
						className={classes.footer}
						width={1}>
						<Box>
							<Container maxWidth="md" style={{ marginTop: '20px', marginBottom: '20px' }}>
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<Button
											onClick={this.reserve}
											variant="contained"
											color="primary"
											fullWidth
											size="large"
											disabled={!this.participation.nameValid || this.participation.email.length === 0}>
											{(!this.participation.nameValid || this.participation.email.length === 0) &&
												`Voer je naam en email in.`
											}
											{this.store.selectedTimeBlock.id && this.participation.nameValid && this.participation.email.length > 0 &&
												`Aanmelden voor ${this.store.selectedTimeBlock.start!.toLocaleString(DateTime.DATE_HUGE)}, ${DateUtils.toTimeString(this.store.selectedTimeBlock!.start)} - ${DateUtils.toTimeString(this.store.selectedTimeBlock!.end)}`
											}
											{!this.store.selectedTimeBlock.id && this.participation.nameValid && this.participation.email.length > 0 &&
												`Afmelden`
											}
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Box>
					</Box >)
				}
			</>
		)
	}

	private renderFinished(): JSX.Element {
		if (!this.store.selectedTimeBlock!.id) {
			return (
				<>
					<Box>
						<Container maxWidth="md">
							<Box className="page-title">
								<Typography variant="h4">Je afmelding is ontvangen.</Typography>
							</Box>
						</Container>
					</Box>
				</>
			);
		}

		return (
			<>
				<Box>
					<Container maxWidth="md">
						<Box className="page-title">
							<Typography variant="h4">Je aanmelding is ontvangen.</Typography>
						</Box>
					</Container>
				</Box>
				<Box className={`section section-highlight`}>
					<Container maxWidth="md">
						<Typography variant="h6">
							{this.store.event!.title}
						</Typography>
						<Typography
							variant="subtitle1">
							Datum: <strong>{DateUtils.toDateString(this.store.selectedTimeBlock!.start, 'PPPP')}</strong>
						</Typography>
						<Typography gutterBottom>
							Tijdstip: <strong>{DateUtils.toTimeString(this.store.selectedTimeBlock!.start)}</strong> tot <strong>{DateUtils.toTimeString(this.store.selectedTimeBlock!.end)}</strong>
						</Typography>
						<Typography>
							Locatie: {this.store.event!.location}
						</Typography>
					</Container>
				</Box>
				<Box className={`section`}>
					<Container maxWidth="md">
						<Typography>
							We zien je graag op {DateUtils.toDateString(this.store.selectedTimeBlock!.start, 'PPPP')} tussen {DateUtils.toTimeString(this.store.selectedTimeBlock!.start)} uur en {DateUtils.toTimeString(this.store.selectedTimeBlock!.end)}. Om {DateUtils.toTimeString(this.store.selectedTimeBlock!.end)} uur
							verzoeken we je vriendelijk plaats te maken voor de volgende gasten.
						</Typography>
						<Typography variant="body1" style={{ marginTop: "20px" }}>
							Je ontvangt binnen enkele minuten een bevestigingsmail van je aanmelding. Deze mail kan in je spam terecht komen. Mocht je niets ontvangen hebben, dan kun je contact met ons opnemen via info@tijdprikker.nl en helpen we je graag verder.
						</Typography>
						{/* <Typography variant="body1" style={{ marginTop: "20px" }}>
							Je ontvangt een bevestigingsmail met mogelijkheid tot het wijzigen en annuleren van je reservering.
							Deze e-mail kan in je spam terecht komen.
						</Typography> */}
					</Container>
				</Box>
			</>
		);
	}

	componentDidMount() {
		this.loadEvent();
	}
}

export default withStyles(styles)(EventParticipation);