import * as qs from 'qs';
import axios from '../lib/Axios';
import { TimeBlockOccupiedError, UserWithSameEmailAlreadyExistsError } from '../lib/Errors';
import Event from '../models/Event';
import EventDay from '../models/EventDay';
import Participation from '../models/Participation';

export default class TransportLayer {
	// AUTH
	signIn = async (username: string, password: string) => {
		try {
			let res = await axios({
				method: 'post',
				url: `/auth.signin`,
				data: qs.stringify({
					username: username,
					password: password
				}),
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
				}
			});
			return res.data;
		} catch (err) {
			console.error("err", err.response.data.errorCode);
			throw err;
		}
	}

	renewToken = async () => {
		try {
			let res = await axios({
				method: 'post',
				url: '/auth.renewtoken'
			});
			return res.data;
		} catch (err) {
			console.error("err", err.response.data.errorCode);
			throw err;
		}
	}

	signUp = async (name: string, email: string, password: string) => {
		try {
			await axios({
				method: 'post',
				url: '/auth.register',
				data: qs.stringify({
					name: name,
					email: email,
					password: password
				})
			});
		} catch (err) {
			console.error("err", err.response.data.errorCode);
			if (err.response.data.errorCode === '10001') throw new UserWithSameEmailAlreadyExistsError();			
			throw err;
		}
	}

	requestPasswordReset = async (email: string) => {
		try {
			await axios({
				method: 'post',
				url: '/password-resets',
				data: qs.stringify({
					email: email
				})
			});
		} catch (err) {
			console.error("err", err.response.data.errorCode);
			throw err;
		}
	}

	getPasswordReset = async (id: string) => {
		try {
			let res = await axios.get(`/password-resets/${id}`);
			return {
				id: id,
				expired: res.data ? res.data.expired : true
			}
		} catch (err) {
			console.error(err);
			throw err;
		}
	}

	resetPassword = async (id: string, password: string) => {
		try {
			let res = await axios.patch(`password-resets/${id}`, { password: password });
			return res.status === 204;
		} catch (err) {
			console.error(err);
			throw err;
		}
	}

	getSignInMethod = async (username: string) => {
		try {
			let res = await axios.get(`/signinmethods?username=${username}`);
			return res.data.method as string;
		} catch (err) {
			console.error(err);
			throw err;
		}
	}

	// EVENT
	fetchOrganizedEventsForUser = async (userId: string) => {
		let res = await axios.get(`users/${userId}/organized-events`);
		return res.data;
	}

	fetchEvent = async (eventId: string) => {
		let res = await axios.get(`/events/${eventId}`);
		return res.data;
	}

	saveEvent = async (event: Event) => {
		//let formattedDate = parseFromTimeZone(dateUtils.format(event.start!, 'yyyy-MM-dd HH:mm'), { timeZone: event.timeZone })
		let body = {
			_id: event.id,
			title: event.title,
			description: event.description,
			timeZone: 'Europe/Amsterdam',
			//date: dateUtils.format(event.date!, 'yyyy-MM-dd'),
			//start: dateUtils.format(event.start!, 'yyyy-MM-dd HH:mm xxx'),
			//end: dateUtils.format(event.end!, 'yyyy-MM-dd HH:mm xxx'),
			location: event.location,
			//numberOfGroupsSimultane: event.numberOfGroupsSimultane,
			//numberOfGroupsInvited: event.numberOfGroupsInvited,
			//numberOfAdultsPerGroup: event.numberOfAdultsPerGroup,
			//numberOfChildrenPerGroup: event.numberOfChildrenPerGroup,
			organizerEmail: event.organizerEmail,
			organizerName: event.organizerName,
		};
		await axios.post('/events', body);
	}

	/// EVENT DAY
	fetchEventDays = async (eventId: string) => {
		let res = await axios.get(`/events/${eventId}/days`);
		return res.data;
	}

	saveEventDay = async (eventDay: EventDay) => {
		let body = {
			_id: eventDay.id,
			eventId: eventDay.eventId,
			date: eventDay.date,
			timeZone: eventDay.timeZone,
			state: eventDay.state,
			configuration: eventDay.configuration,
			timeBlocks: eventDay.timeBlocks
		};
		if (eventDay.isNew)
			await axios.post(`/events/${eventDay.eventId}/days`, body);
		else 
			await axios.put(`/events/${eventDay.eventId}/days/${eventDay.id}`, body);
	}

	fetchEventCapacity = async (eventId: string) => {
		let res = await axios.get(`/events/${eventId}/capacity`);
		return res.data;
	}

	activateEventDay = async (eventId: string, eventDayId: string) => {
		await axios.post(`/events/${eventId}/days/${eventDayId}/.activate`)
	}

	// REGISTRATIONS 
	fetchRegistrations = async (eventId: string) => {
		let res = await axios.get(`/events/${eventId}/registrations`);
		return res.data;
	}

	// PUBLIC - EVENT
	fetchPublicEvent = async (eventId: string) => {
		let res = await axios.get(`/public/events/${eventId}`);
		return res.data;
	}

	// PUBLIC - REGISTRATION
	saveRegistration = async (participation: Participation) => {
		let body = {
			_id: participation.id,
			eventId: participation.eventId,
			eventDayId: participation.eventDayId,
			name: participation.name,
			email: participation.email,
			timeBlockId: participation.timeBlockId,
			numberOfAdults: participation.numberOfAdults,
			numberOfChildren: participation.numberOfChildren
		};

		try {
			await axios.post(`/public/registrations`, body);
		} catch (err) {
			if (err.response.data.errorCode === 40001) {
				throw new TimeBlockOccupiedError();
			}
			console.log("err", err.response.data.errorCode);
		}
	}
}