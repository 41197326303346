import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IEventDayConfiguration } from '../../../models/EventDay';
import { Box, Typography, withTheme } from '@material-ui/core';
import {  DateUtils } from '../../../lib/DateUtils';
import GroupIcon from '@material-ui/icons/Group';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1)
    },
    header: {
        marginBottom: theme.spacing(1)
    },
    error: {
        color: theme.palette.error.dark,
        fontWeight: 'bold'
    },
    value: {
        fontSize: '1.5rem'
    },
    valueSmall: {
        fontSize: '.8rem'
    },
    iconSuffix: {
        marginLeft:'4px', 
        marginBottom:'-4px'
    },
    errorMessage:{
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1)
        }
    }
}));

interface IProps {
    values: IEventDayConfiguration
    messages: string [],
    startError?: boolean,
    endError?: boolean,
    dateError?: boolean,
    blockCountError?: boolean,
    blockDurationError?: boolean,
    pauseError?: boolean,
    simultaneGroupCountError?: boolean,
    totalGroupCountError?: boolean
}

export default withTheme((props: IProps) => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.root} bgcolor="info.main" color="white">
                <Box display="flex" flexDirection="row" justifyContent="space-around" className={classes.header}>
                    <Typography className={clsx([props.dateError && classes.error])}>
                        {DateUtils.toDateString(props.values.date)}
                    </Typography>
                    <Typography className={classes.value}>
                        <span className={clsx([props.startError && classes.error])}>{DateUtils.toTimeString(props.values!.start)}</span> - <span className={clsx([props.endError && classes.error])}>{DateUtils.toTimeString(props.values!.end)}</span>
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="space-around">
                    <Box textAlign="center" className={clsx([props.blockCountError && classes.error])}>
                        <Typography className={classes.value}>{props.values!.timeBlockCount !== undefined ? props.values!.timeBlockCount : '__'}</Typography>
                        <Typography>blok{props.values!.timeBlockCount !== undefined && props.values!.timeBlockCount > 1 ? 'ken' : ''}</Typography>
                    </Box>
                    <Box textAlign="center"  className={clsx([props.blockDurationError && classes.error])}>
                        <Typography>
                            <span className={classes.value}>{props.values?.timeBlockDuration !== undefined ? Math.max(0, props.values!.timeBlockDuration) : '__'}</span>
                            <span className={classes.valueSmall}> min</span>
                        </Typography>
                        <Typography>per blok</Typography>
                    </Box>
                    <Box textAlign="center" className={clsx([props.pauseError && classes.error])}>
                        <Typography>
                            <span className={classes.value}>{props.values?.timeBlockMeantimeDuration !== undefined ? Math.max(0, props.values!.timeBlockMeantimeDuration) : '__'}</span>
                            <span className={classes.valueSmall}> min</span>
                        </Typography>
                        <Typography>pauze</Typography>
                    </Box>
                    <Box textAlign="center" className={clsx([props.simultaneGroupCountError && classes.error])}>
                        <Typography>
                            <span className={classes.value}>{props.values.simultaneGroupCount !== undefined ? props.values.simultaneGroupCount : '__'}</span>
                            <span className={classes.valueSmall}><GroupIcon className={classes.iconSuffix}/></span>
                        </Typography>
                        <Typography>per blok</Typography>
                    </Box>
                    <Box textAlign="center" className={clsx([props.totalGroupCountError && classes.error])}>
                        <Typography>
                            <span className={classes.value}>{props.values.totalGroupCount !== undefined ? props.values.totalGroupCount : '__'}</span>
                            <span className={classes.valueSmall}><GroupIcon className={classes.iconSuffix}/></span>
                        </Typography>
                        <Typography>totaal</Typography>
                    </Box>
                </Box>
            </Box>    
            {props.messages.length > 0 && 
                <Box bgcolor="error.main" color="white" p={2}>
                    {props.messages.map(m => 
                        <Typography key={m} className={classes.errorMessage}>{m}</Typography>
                    )}
                </Box>
            }
        </>
    );    
});