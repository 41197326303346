import * as jwt from 'jsonwebtoken';
import { action, computed, observable } from 'mobx';
import axios from '../lib/Axios';
import User from '../models/User';
import RootStore from './RootStore';
import TransportLayer from './TransportLayer';

export default class AuthStore {
	private rootStore: RootStore;
	transportLayer: TransportLayer;
	@observable user: User | null = null;
	@observable private token: string = '';
	private tokenExpirationAt: number = 0;
	private tokenIssuedAt: number = 0;
	private refreshTimeout: NodeJS.Timeout | null = null;

	@computed get isAuthenticated() {
		return this.token !== '' && this.user !== null;
	}

	constructor(rootStore: RootStore, transportLayer: TransportLayer) {
		this.rootStore = rootStore;
		this.transportLayer = transportLayer;
		this.loadFromLocalStorage();
		this.addAxiosAuthenticationMiddleware();
	}

	@action register = async (name: string, email: string, password: string) => {
		await this.transportLayer.signUp(name, email, password);
	}

	@action signIn = async (username: string, password: string) => {
		try {
			let data = await this.transportLayer.signIn(username, password);
			this.updateFromToken(data.token);
			this.updateLocalStorage();
			return true;
		} catch (reason) {
			console.error('Authentication failed', reason);
			return false;
		}
	}

	@action signOut = async () => {
		setTimeout(() => {
			this.token = '';
			this.user = null;
			localStorage.clear();

			this.rootStore.eventStore.clear();
			this.rootStore.eventCapacityStore.clear();
			this.rootStore.participationStore.clear();
		}, 500);
	}

	private updateLocalStorage = () => {
		if (this.token) {
			localStorage.setItem('auth.token', this.token);
		} else {
			localStorage.removeItem('auth.token');
		}
	}

	private scheduleTokenRefresh = () => {
		let now = new Date().getTime();
		let interval = (this.tokenExpirationAt * 1000) - now - (60 * 1000); // 1 minute before expiry

		this.refreshTimeout = setTimeout(async () => {
			try {
				let data = await this.transportLayer.renewToken();
				this.updateFromToken(data.token);
				this.updateLocalStorage();
			} catch (err) {
				console.error(err);
				this.signOut();
			}
		}, interval);
	}

	@action private updateFromToken = (token: string) => {
		let decodedToken: any = jwt.decode(token, { complete: true });
		if (new Date().getTime() - (60 * 1000) > decodedToken.payload.exp * 1000) {
			this.signOut();
		}
		let user = new User();
		user.updateFromServer(decodedToken.payload.data);
		this.token = token;
		this.tokenExpirationAt = decodedToken.payload.exp;
		this.tokenIssuedAt = decodedToken.payload.iat;
		this.user = user;
		this.scheduleTokenRefresh();
	}

	@action private loadFromLocalStorage = () => {
		let token = localStorage.getItem('auth.token') as string;
		if (token) {
			this.updateFromToken(token);
			this.token = token;
		}
	}

	private addAxiosAuthenticationMiddleware = () => {
		axios.interceptors.request.use(config => {
			config.headers['authorization'] = `bearer ${this.token}`;
			return config;
		});
	}
}