import _ from 'lodash';
import { action, observable } from "mobx";
import PublicStore from '../stores/PublicStore';
import PublicEventDay from './PublicEventDay';

export default class PublicEvent {
	private store: PublicStore;

	constructor(store: PublicStore) {
		this.store = store;
	}

	@observable id: string | undefined = undefined;
	@observable title: string | undefined = '';
	@observable description: string | undefined = '';
	@observable location: string = '';
	@observable organizerName: string = '';
	@observable days: PublicEventDay[] = [];

	@action updateFromServer = (data: any) => {
		this.id = data._id;
		this.title = data.title;
		this.description = data.description;
		this.location = data.location;
		this.organizerName = data.organizerName;

		if (data.days) {
			data.days.forEach((d: any) => {
				let day = new PublicEventDay();
				day.updateFromServer(d);
				this.days.push(day);
			});
		}
	}

	reload = async () => {
		if (this.id)
			await this.store.loadEvent(this.id);
	}
}