import { Box, Button, Container, Grid, Link, makeStyles, Theme, Typography, withTheme } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import InfoStep from '../../components/InfoStep';
import RootStore from '../../stores/RootStore';
import StartDialog from './components/StartDialog';

const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: 'white'
	},
	homeSection: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10)
	},
	imageSection: {
		backgroundImage: `linear-gradient(
			rgba(0, 0, 0, 0.65), 
			rgba(0, 0, 0, 0.65)
		  ), url(${"content/assets/home-background3.jpg"})`,
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		color: 'white'
	},
	startNowButton: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	paragraph: {
		marginBottom: theme.spacing(2)
	},
}));


interface IProps {
	rootStore?: RootStore,
	getStartedDialogOpen?: boolean
}

const Home = withTheme(inject('rootStore')(observer((props: React.PropsWithChildren<IProps>) => {
	const classes = useStyles();

	const image = { 
		shadeOverlay: 0.5,
		src: 'content/assets/home-background3.jpg',
		authorName: 'Viktor Hanacek',
		authorUrl: 'https://picjumbo.com/author/viktorhanacek/',
		photoSiteName: 'picjumbo',
		photoSiteUrl: 'https://picjumbo.com/freelance-woman-working-on-her-macbook/'
	}

	return (
		<>
			<Box className={`page-content`}>
				<Box className={`${classes.homeSection} ${classes.imageSection}`} 
					style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, ${image.shadeOverlay}), rgba(0, 0, 0, ${image.shadeOverlay})), url(${image.src})`}}>
					<Container maxWidth="md">
						<Grid container spacing={2}>
							<Grid item xs={12} sm={7}>
								<Typography variant="h3" component="h1" style={{ marginBottom: '30px' }}>
									Plan je bijeenkomst, verdeeld in tijdsblokken
								</Typography>
								<Typography variant="body1" style={{ marginBottom: '80px' }}>
									Beperkte ruimte om al je gasten tegelijk te ontvangen? Of wil je de gasten verdelen over de dag? Deel je bijeenkomst in tijdsblokken en laat je genodigden kiezen wanneer ze komen.
								</Typography>
								<Button variant="contained" color="primary" size="large" className={classes.startNowButton}
									onClick={e => props.rootStore!.routerStore.push("/get-started")}>
									Plan een bijeenkomst
								</Button>
							</Grid>
						</Grid>
						
					</Container>
				</Box>
				<Box style={{marginTop:-55,marginRight:5}} display="flex" justifyContent="flex-end">
					<Typography variant="subtitle2" style={{fontSize:".7rem"}}>Photo by <Link href={image.authorUrl} target="_blank">{image.authorName}</Link> from <Link href={image.photoSiteUrl} target="_blank">{image.photoSiteName}</Link></Typography>
				</Box>				
			</Box>
			<Box className={`section-highlight ${classes.homeSection}`}>
				<Container maxWidth="md">
					<Grid container spacing={5}>
						<Grid item xs={12} sm={5}>
							<Typography variant="h4" component="h1" style={{ marginBottom: '30px' }}>
								Hoe het werkt
							</Typography>
							<Typography variant="body1" paragraph>
								Je geeft door wanneer je bijeenkomst plaatsvindt (datum &amp; tijdstip) en hoeveel huishoudens* je tegelijk kunt ontvangen op je locatie. Het systeem berekent automatisch hoeveel tijdsblokken er nodig zijn om al je gasten binnen je opgegeven tijd te kunnen ontvangen.
							</Typography>
							<Typography variant="body1" paragraph>
								Vervolgens krijg je een link die je kunt delen met je gasten. Als zij op de link klikken, kunnen ze een tijdsblok naar keuze kiezen. Vol = vol.
							</Typography>
							<Typography variant="body1" paragraph>
								Als organisator van de bijeenkomst kun je zien wie wanneer komt.
							</Typography>
							<Typography variant="subtitle2" className={classes.paragraph} color="textSecondary">
								* Check altijd de site van het RIVM voor de laatste richtlijnen m.b.t. het coronavirus.
							</Typography>
						</Grid>
						<Grid item xs={12} sm={7}>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<InfoStep index="1">
										<Typography variant="h5" component="h2">
											Plan je bijeenkomst
										</Typography>
										<Typography variant="subtitle1">
											Vul de details in over je bijeenkomst en je tijdsblokken worden automatisch gemaakt.
										</Typography>
									</InfoStep>
								</Grid>
								<Grid item xs={12}>
									<InfoStep index="2">
										<Typography variant="h5" component="h2">
											Verstuur de uitnodigingen
										</Typography>
										<Typography variant="subtitle2">
											Je ontvangt een link die je kunt delen met de genodigden.
										</Typography>
									</InfoStep>
								</Grid>
								<Grid item xs={12}>
									<InfoStep index="3">
										<Typography variant="h5" component="h2">
											Genodigden reserveren een tijdsblok
										</Typography>
										<Typography variant="subtitle2">
											Via de link kun je direct een tijdsblok naar keuze kiezen. Met 1 druk op de knop geregeld!
										</Typography>
									</InfoStep>
								</Grid>
								<Grid item xs={12}>
									<Box display="flex" justifyContent="center">
										<Button variant="contained" color="primary" size="large" className={classes.startNowButton}
											onClick={e => props.rootStore!.routerStore.push("/get-started")}>
											Aan de slag
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<StartDialog 
				open={props.getStartedDialogOpen ?? false} 
				onClose={() => props.rootStore!.routerStore.push('/')}
				onSubmit={(event) => props.rootStore!.routerStore.push(`my/events/${event.id}`)}
			/>

			<Box className={classes.homeSection}>
				<Container maxWidth="md">
					<Box display="flex" flexDirection="row" justifyContent="center">
						<Typography variant="h4" component="h1" paragraph={true}>Tijdprikker is mogelijk gemaakt door:</Typography>
					</Box>
					<Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="center" flexWrap="wrap">
						<Box p={2}>
							<Link href="https://triact.net" target="_blank">
								<img src="content/assets/triact-logo.png" alt="triact" width="200"/>
							</Link>
						</Box>
						<Box p={2}>
							<Link href="https://lavart.nl" target="_blank">
								<img src="content/assets/lavart-logo.png" alt="lavart" width="200"/>
							</Link>
						</Box>
					</Box>
				</Container>
			</Box>
		</>
	);
})));

export default Home;
