import _ from 'lodash';
import { action, observable } from "mobx";
import { RegistrationStore } from '../stores/RegistrationStore';
const { v4: uuidv4 } = require('uuid');

export class Registration {
	private store: RegistrationStore;

	constructor(store: RegistrationStore, id = uuidv4()) {
		this.store = store;
		this.id = id;
	}

	@observable id: string;
	@observable eventId: string | undefined;
	@observable eventDayId: string | undefined;
	@observable timeBlockId: string | undefined;
	@observable name: string | undefined;
	@observable email: string | undefined;
	@observable numberOfAdults: number | undefined;
	@observable numberOfChildren: number | undefined;

	@action updateFromServer = (data: any) => {
		this.id = data._id;
		this.eventId = data.eventId;
		this.eventDayId = data.eventDayId;
		this.timeBlockId = data.timeBlockId;
		this.name = data.name;
		this.email = data.email;
		this.numberOfAdults = data.numberOfAdults;
		this.numberOfChildren = data.numberOfChildren;
	}
}
