import { action, computed, observable } from 'mobx';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import PublicStore from '../stores/PublicStore';
import PublicTimeBlockV2 from './PublicTimeBlockV2';

/**
 * Downgraded public version of EventDay.
 */
export default class PublicEventDay {
	@observable id: string = '';
	@observable eventId: string = '';
	@observable date: DateTime = DateTime.invalid(' ');
	@observable timeZone: string = 'Europe/Amsterdam';
	@observable timeBlocks: PublicTimeBlockV2[] = [];
	@observable state: 'active'|'inactive' = 'inactive';

	@computed get isOccupied(): boolean {
		return _.sumBy(this.timeBlocks, tb => tb.slotsAvailable) === 0;
	}

	@computed get slotCount(): number {
		return _.sumBy(this.timeBlocks, tb => tb.maxNumberOfRegistrations);
	}

	@computed get registrationCount() : number {
		return _.sumBy(this.timeBlocks, tb => tb.registrations.length);
	}

	@computed get slotsAvailable() : number {
		return _.sumBy(this.timeBlocks, tb => tb.slotsAvailable);
	}

	@action updateFromServer = (data: any) => {
		this.id = data._id;
		this.date = DateTime.fromISO(data.date, { zone: data.timeZone });
		this.timeZone = data.timeZone;
		this.eventId = data.eventId;
		this.state = data.state;
		data.timeBlocks.forEach((d:any) => {
			let tb = new PublicTimeBlockV2(this);
			tb.updateFromServer(d);
			this.timeBlocks.push(tb); 
		});
	}
}