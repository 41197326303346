import cyan from '@material-ui/core/colors/cyan';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// Create a theme instance.
const theme = responsiveFontSizes(createMuiTheme({
	typography: {
		fontFamily: 'Raleway, Arial'
	},
	palette: {
		primary: {
			main: cyan[700],
		},
		// secondary: {
		// 	main: '#19857b',
		// },
		// error: {
		// 	main: red.A400,
		// },
		// background: {
		// 	default: '#fff',
		// },
		info: {
			main: cyan[700]
		}
	},
}));

export default theme;