import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTheme, withTheme } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MobileStepper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

// const useStyles = makeStyles((theme) => ({
// }))

interface IProps {
    disableBackdropClick?: boolean,
    title: string,
    finishButtonText: string,
    finishButtonDisabled?: boolean,
    fullScreen?: boolean,
    fullWidth?: boolean,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
    open: boolean,
    onClose?: () => void;
    onSubmit?: () => void;
    onValidateStep?: (index: number) => boolean;
    scroll?: 'body' | 'paper';
}

export default withTheme(observer((props: React.PropsWithChildren<IProps>) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const handleNext = () => {
        if (!props.onValidateStep || props.onValidateStep(activeStep))
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
    };

    const handleClose = () => {
        if (props.onClose) props.onClose();
    }

    const handleSubmit = () => {
        if (props.onSubmit) props.onSubmit();        
    }

    const stepCount = React.Children.count(props.children);

    return (
        <>
            <Dialog 
                fullScreen={props.fullScreen} 
                onClose={handleClose} 
                open={props.open}
                aria-labelledby="dialog"
                maxWidth={props.maxWidth}
                fullWidth={props.fullWidth}
                scroll={props.scroll}
                disableBackdropClick={props.disableBackdropClick}>
                    <DialogTitle>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box flexGrow={1}>
                                {props.title}
                            </Box>
                            <Box>
                                <IconButton aria-label="close" color="primary" onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        {React.Children.toArray(props.children)[activeStep]}
                    </DialogContent>
                    <DialogActions style={{padding:0}}>
                        <Box flexGrow={1}>
                            <MobileStepper
                                steps={stepCount}
                                position="static"
                                variant="text"
                                activeStep={activeStep}
                                nextButton={(
                                    <>
                                        {activeStep < stepCount - 1 && ( 
                                            <Button size="small" onClick={handleNext}>
                                                Volgende
                                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                            </Button>
                                        )}
                                        {activeStep === stepCount - 1 && (
                                            <Button size="small" onClick={handleSubmit} variant="contained" color="primary" disabled={props.finishButtonDisabled !== undefined && props.finishButtonDisabled}>
                                                {props.finishButtonText}
                                            </Button>
                                        )}
                                    </>
                                )}
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                        Vorige
                                    </Button>
                                }
                            />
                        </Box>
                    </DialogActions>
            </Dialog>
        </>
    );
}));