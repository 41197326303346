
import { Settings as LuxonSettings } from 'luxon';

class Settings {
	apiBaseUrl: string = '';
	webBaseUrl: string = '';
}

/* Local */
// const settings: Settings = {
//  	apiBaseUrl: 'http://localhost:3001',
// 	webBaseUrl: 'http://localhost:3000',
// }

/* Local IP cable */
// const settings: Settings = {
// 	apiBaseUrl: 'http://192.168.0.52:3001',
//    	webBaseUrl: 'http://192.168.0.52:3000',
// }

/* Development */
// const settings: Settings = {
// 	apiBaseUrl: 'https://api.dev.tijdprikker.nl',
//   webBaseUrl: 'https://dev.tijdprikker.nl',
// }

/* Production */
const settings: Settings = {
	apiBaseUrl: 'https://api.tijdprikker.nl',
	webBaseUrl: 'https://tijdprikker.nl'
}

// Luxon configuration
LuxonSettings.defaultLocale = "nl";

export default settings;