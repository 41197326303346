import { Box, Card, CardContent, createStyles, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
//import blue from '@material-ui/core/colors/blue';
import React from 'react';

const styles = (theme: Theme): any => createStyles({
	root: {
		//margin: theme.spacing(2),
		//backgroundColor: blue[100]
	}
});

interface IProps extends WithStyles<typeof styles> {
	index: string;
}

const InfoStep = (props: React.PropsWithChildren<IProps>) => {
	const { classes } = props;
	return (
		<Card className={classes.root}>
			<CardContent>
				<Box display="flex">
					<Box width="70" display="flex" alignItems="center">
						<Box textAlign="center">
							<Typography variant="body1" component="div">STAP</Typography>
							<Typography variant="h3" component="div" style={{ lineHeight: 1 }}>{props.index}</Typography>
						</Box>
					</Box>
					<Box flexGrow={1} style={{ paddingLeft: '30px' }}>
						{props.children}
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};

export default withStyles(styles)(InfoStep);