import { AppBar, Box, Button, Container, createStyles, MenuItem, Popover, Theme, Toolbar, Typography, withStyles, WithStyles } from '@material-ui/core';
import { action, computed, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import SignInForm from '../components/SignInForm';
import RootStore from '../stores/RootStore';


const styles = (theme: Theme) => createStyles({
	root: {
	},
	appBar: {
		backgroundColor: 'white',
		color: theme.palette.text.primary
	},
	title: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -18,
		marginRight: 10,
	}
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore
}

@inject('rootStore')
@observer
class MyAppBar extends React.Component<IProps, {}> {

	@observable private userMenuAnchorEl: any;

	@computed get isUserMenuOpen() {
		return Boolean(this.userMenuAnchorEl);
	}

	@computed get showSignInForm() {
		return !this.props.rootStore!.authStore.isAuthenticated;
	}

	constructor(props: IProps) {
		super(props);
		reaction(
			() => props.rootStore!.authStore.isAuthenticated,
			(count, reaction) => {
				this.closeUserMenu();
			}
		);
	}

	@action private handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		this.userMenuAnchorEl = event.currentTarget;
	};

	@action private closeUserMenu = () => {
		this.userMenuAnchorEl = null;
	};

	@action private handleSignOut = async () => {
		this.closeUserMenu();
		this.props.rootStore!.authStore.signOut();
		this.props.rootStore!.routerStore.push('/');
	}
	@action private handleMyEvents = async () => {
		this.closeUserMenu();
		this.props.rootStore!.routerStore.push('/my/events');
	}

	private renderUserMenu() {
		return (
			<>
				<MenuItem onClick={this.handleMyEvents}>Bijeenkomsten</MenuItem>
				<MenuItem onClick={this.handleSignOut}>Logout</MenuItem>
			</>
		);
	}

	private onSignIn = () => {
		this.props.rootStore!.routerStore.push('/my/events');
	}

	@action private onPasswordReset = () => {
		this.closeUserMenu();
	}

	private renderSignInMenu() {
		return (
			<Box className={`paper-padding`}>
				<SignInForm onSignIn={this.onSignIn} onPasswordReset={this.onPasswordReset} />
			</Box>
		);
	}

	render() {
		const { classes } = this.props;
		return (
			<AppBar position="fixed" className={classes.appBar} elevation={0}>
				<Container maxWidth="md">
					<Toolbar>
						{/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
								<MenuIcon />
							</IconButton> */}
						<img src={process.env.PUBLIC_URL + '/assets/logo.png'} alt="tijdprikker logo" height="50px" className={`pointer`} onClick={() => this.props.rootStore!.routerStore.push('/')} />
						<Typography className={classes.title}></Typography>
						<>
							<Button
								aria-controls="user-menu"
								aria-haspopup="true"
								onClick={this.handleUserMenu}>
								{!this.showSignInForm && this.props.rootStore!.authStore.user!.name}
								{this.showSignInForm && `Inloggen`}
							</Button>
							<Popover
								id="user-menu"
								anchorEl={this.userMenuAnchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right'
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								open={this.isUserMenuOpen}
								onClose={this.closeUserMenu}
							>
								<Container maxWidth="sm" disableGutters={true}>
									{!this.showSignInForm && this.renderUserMenu()}
									{this.showSignInForm && this.renderSignInMenu()}
								</Container>
							</Popover>
						</>
						{/*}*/}
					</Toolbar>
				</Container>
			</AppBar >
		);
	}
}


export default withStyles(styles)(MyAppBar);