import _ from 'lodash';
import { DateTime } from 'luxon';
import { action, computed, observable } from "mobx";
import EventStore from '../stores/EventStore';
import EventDay from './EventDay';
const { v4: uuidv4 } = require('uuid');

export default class Event {
	private store: EventStore;

	constructor(store: EventStore, id = uuidv4()) {
		this.store = store;
		this.id = id;
	}

	@observable id: string | undefined = undefined;
	@observable ownerUserId: string | undefined = undefined;
	@observable title: string | undefined = '';
	@observable description: string | undefined = '';
	@observable location: string = '';
	@observable organizerName: string = '';
	@observable organizerEmail: string = '';
	@observable minDayDate: DateTime | undefined;
	@observable maxDayDate: DateTime | undefined;
	@observable cancellations: EventCancellationRegistration[] = [];
	@observable days: EventDay[] = [];

	@computed get hasActiveDays() : boolean {
		return this.days !== undefined && _.some(this.days, d => d.state === 'active');
	}
	@computed get hasInactiveDays() : boolean {
		return this.days !== undefined && _.some(this.days, d => d.state === 'inactive');
	}
	@computed get cancellationCount(): number {
		return this.cancellations.length;
	}
	@action setTitle = (title: string) => { this.title = title; }
	@action setLocation = (value: string) => { this.location = value; }
	@action setOrganizerEmail = (value: string) => { this.organizerEmail = value; }
	@action setOrganizerName = (value: string) => { this.organizerName = value; }

	@action updateFromServer = (data: any) => {
		this.id = data._id;
		this.title = data.title;
		this.description = data.description;
		this.location = data.location;
		this.organizerEmail = data.organizerEmail;
		this.organizerName = data.organizerName;
		this.ownerUserId = data.ownerUserId;
		this.minDayDate = DateTime.fromISO(data.minDayDate, { zone: data.timeZone });;
		this.maxDayDate = DateTime.fromISO(data.maxDayDate, { zone: data.timeZone });;
		this.cancellations = [];

		if (data.cancellations) {
			data.cancellations.forEach((c:any) => {
				this.cancellations.push(new EventCancellationRegistration(c.id, c.name));
			});
		}
	}

	@action addDay = (day: EventDay) => {
		this.days.push(day);
	}

	save = async () => {
		await this.store.transportLayer.saveEvent(this);
	}

	reload = async (options?: {eventDays: boolean, registrations: boolean}) => {
		await Promise.all([
			this.store.loadEvent(this.id!),
			new Promise<void>((resolve, reject) => { 
				if (options !== undefined && options?.eventDays) {
					this.store.rootStore.eventDayStore.loadDays(this).then(() => resolve());
				}
				resolve();
			}),
			new Promise<void>((resolve, reject) => { 
				if (options !== undefined && options?.registrations) {
					return this.store.rootStore.registrationStore.loadForEvent(this).then(() => resolve());
				}
				resolve();
			})
		]);
	}
}

class EventCancellationRegistration {
	@observable id: string | undefined;
	@observable name: string | undefined;

	constructor(participationId: string, name: string) {
		this.id = participationId;
		this.name = name;
	}
}