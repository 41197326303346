import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { Box, Button, Container, Drawer, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { LocalStorage } from '../../../lib/LocalStorage';
import CloseIcon from '@material-ui/icons/Close';
import { useWindowSize } from '../../../lib/hooks/useWindowSize';
import { PropTypes } from 'mobx-react';
import { isWhiteSpaceLike } from 'typescript';

const useStyles = makeStyles((theme) => ({
    closeIcon: {
        cursor: 'pointer'
    },
    link: {
        color: 'white',
        textDecoration: 'underline'
    },
    buttonConfirm: {
        color: theme.palette.primary.main,
        borderColor: 'white',
        backgroundColor: 'white'
    },
    buttonCustomize: {
        color: 'white',
        textTransform: 'none',
        marginRight: theme.spacing(2)
    }
}));

interface IProps {
    open: boolean;
    onCustomize: () => void;
    onConfirm: () => void;
}

const CookieConsent = withTheme((props: IProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [height, setHeight] = useState(0);
    const drawerRef = useRef(null);

    useWindowSize((windowSize) => {
        // update height of the background div to prevent that the page is behind the drawer.
        if (open && drawerRef && drawerRef.current) {
            const h = (drawerRef.current as any).offsetHeight;
            if (h != height) {
                setHeight(h);
            }
        }
    });

    useEffect(() => {
        // remove height when drawer is closed.
        if (!open && height > 0) {            
            setHeight(0);
        }
    }, [open]);

    const handleConfirm = () => {
        props.onConfirm();
    }

    const handleCustomize = () => {
        props.onCustomize();
    }

    return (
        <>
            <Box style={{height:height}}/>
            <Drawer anchor="bottom" open={props.open} variant="persistent">
                <div ref={drawerRef}>
                    <Box bgcolor="primary.main" color="white" p={2}>
                        <Container maxWidth="md">
                            <Box>
                                <Typography variant="subtitle2" paragraph={true}>
                                    Wij en onze partners gebruiken cookies om jouw ervaring te personaliseren, voor statistieken en marketingdoeleinden.
                                    Door op 'zelf instellen' te klikken, kun je meer lezen over onze cookies en je voorkeuren aanpassen.
                                    Door op 'Accepteren en doorgaan' te klikken, ga je akkoord met het gebruik van de cookies zoals omschreven in
                                    ons <Link component={RouterLink} to="/about-us/cookie-policy" className={classes.link}>cookiebeleid</Link>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <Button size="small" variant="text" className={classes.buttonCustomize} onClick={handleCustomize}>Zelf instellen</Button>
                                <Button size="small" variant="contained" className={classes.buttonConfirm} onClick={handleConfirm}>Akkoord en doorgaan</Button>
                            </Box>
                        </Container>            
                    </Box>
                </div>
            </Drawer>
        </>
    );
});

export { CookieConsent };
