import { Box, Button, CircularProgress, Container, createStyles, Grid, TextField, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import passwordValidator from '../../lib/PasswordValidator';
import RootStore from '../../stores/RootStore';


const styles = (theme: Theme): any => createStyles({
	signinSection: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10)
	},
	field: {
		marginBottom: theme.spacing(1)
	},
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
	id: string
}


@inject('rootStore')
@observer
class ResetPassword extends React.Component<IProps, {}> {

	@observable private passwordReset: any | undefined;
	@observable private password1: string = '';
	@observable private password2: string = '';
	@observable private attempt: number = 0;
	@observable private viewState: 'loading' | 'expired' | 'loaded' | 'updating' | 'successful' | 'failed' = 'loading';

	@computed get password1Valid() {
		return passwordValidator.validate(this.password1) as boolean;
	}

	@computed get password2Valid() {
		return this.password1 === this.password2;
	}

	@action initialize = async () => {
		if (!this.passwordReset) {
			this.passwordReset = await this.props.rootStore!.authStore.transportLayer.getPasswordReset(this.props.id);

			if (this.passwordReset.expired) {
				this.viewState = 'expired'
			} else {
				this.viewState = 'loaded';
			}
		}
	}

	@action private changePassword = async () => {
		this.attempt++;
		if (this.password1Valid && this.password2Valid) {
			this.viewState = 'updating';
			let res = await this.props.rootStore!.authStore.transportLayer.resetPassword(this.props.id, this.password1);
			this.viewState = res ? 'successful' : 'failed';
		}
	}

	render() {
		const { classes } = this.props;

		return (
			<>
				<Box className={`page-content`}>
					<Box className={`${classes.signinSection}`}>
						<Container maxWidth="md">
							{this.viewState === 'loading' &&
								<CircularProgress />
							}
							{this.viewState === 'expired' &&
								<Typography>Helaas, deze link is niet meer geldig.</Typography>
							}
							{this.viewState === 'loaded' &&
								<Grid container spacing={2}>
									{this.attempt >= 1 && (!this.password1Valid || !this.password2Valid) &&
										<Grid item xs={12}>
											<Alert severity="error">
												{!this.password1Valid && <Typography>Het wachtwoord is niet geldig. Het wachtwoord moet tussen de 8 en 30 karakters lang zijn. Gebruik minimaal een hoofdletter,  kleine letter en een cijfer.</Typography>}
												{!this.password2Valid && <Typography>De wachtwoorden komen niet overeen.</Typography>}
											</Alert>
										</Grid>
									}
									<Grid item xs={12}>
										<TextField
											id="password-1"
											label="Wachtwoord"
											type="password"
											value={this.password1}
											onChange={(e) => this.password1 = e.target.value}
											error={this.attempt > 0 && !this.password1Valid}
											fullWidth
											required />
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="password-2"
											label="Herhaal wachtwoord"
											type="password"
											value={this.password2}
											onChange={(e) => this.password2 = e.target.value}
											error={this.attempt > 0 && !this.password2Valid}
											fullWidth
											required />
									</Grid>
									<Grid item xs={12}>
										<Button color="primary" variant="contained" onClick={this.changePassword}>Wijzig wachtwoord</Button>
									</Grid>
								</Grid>
							}
							{this.viewState === 'updating' &&
								<Box display="flex">
									<CircularProgress />
									<Typography>Je wachtwoord wordt gewijzigd...</Typography>
								</Box>
							}
							{this.viewState === 'successful' &&
								<Box>
									<Typography>Je wachtwoord is gewijzigd.</Typography>
								</Box>
							}
							{this.viewState === 'failed' &&
								<Box>
									<Typography>Het wijzigen van je wachtwoord is mislukt.</Typography>
								</Box>
							}
						</Container>
					</Box>
				</Box>
			</>
		);
	}

	componentDidMount = async () => {
		await this.initialize();
	}
}

export default withStyles(styles)(ResetPassword);
