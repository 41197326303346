import { Box, Button, Container, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, makeStyles, Step, StepContent, StepLabel, Stepper, Theme, Typography, WithStyles, withStyles, withTheme } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import clsx from 'clsx';
import _ from 'lodash';
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import pMinDelay from 'p-min-delay';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EventShareLink from '../../components/EventShareLink';
import EventSummary from '../../components/EventSummary';
import Event from '../../models/Event';
import EventDay, { IEventDayConfiguration } from '../../models/EventDay';
import RootStore from '../../stores/RootStore';
import AddDayDialog from './components/AddDayDialog';
import MyEventDays from './components/MyEventDays';
import MyEventDaysForNewEvent from './components/MyEventDaysForNewEvent';

const useStyles = makeStyles((theme) => ({
	timeBlock: {
		padding: theme.spacing(1),
		backgroundColor: 'white',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRadius: 4,
		borderColor: grey[400]
	},
	marginRight: {
		marginRight: theme.spacing(2)
	},
	marginTop: {
		marginTop: theme.spacing(2)
	}
}));

interface IProps {
	rootStore?: RootStore,
	eventId: string
}

export default withTheme(inject('rootStore')(observer((props: IProps) => {
	const classes = useStyles();
	const eventDayStore = props.rootStore!.eventDayStore;
	const registrationStore = props.rootStore!.registrationStore;
	const event: Event = props.rootStore!.eventStore.get(props.eventId)!;
    const [isAddDayDialogOpen, setIsAddDayDialogOpen] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [isConfirmPlanningDialogOpen, setIsConfirmPlanningDialogOpen] = useState(false);
	const [editEventDay, setEditEventDay] = useState<EventDay|undefined>(undefined);
	const [showGetStartedFlow, setShowGetStartedFlow] = useState(false);

	// init
	useEffect(() => {
		if (!event.hasActiveDays) {
			setShowGetStartedFlow(true);
		}
	}, [event]);

	const handleAddDayDialogClose = () => {
        setIsAddDayDialogOpen(false);
        setEditEventDay(undefined);
    }

    const handleSubmitEventDay = (config: IEventDayConfiguration) => {
        if (editEventDay) {
            // update event day
            editEventDay.configure(config);
            editEventDay.save();
        } else {
            // create event day
            let day = eventDayStore.createEventDay(config.date);
            day.configure(config);
            day.save();
        }
        setIsAddDayDialogOpen(false);
        setEditEventDay(undefined);
    }

	const handleAddEventDay = () => {
		setEditEventDay(undefined);
		setIsAddDayDialogOpen(true);
	}

	const handleEditEventDay = (eventDay: EventDay) => {
		setEditEventDay(eventDay);
		setIsAddDayDialogOpen(true);
	}

	const handleStartPlanningConfirmation = () => {
		setIsConfirmPlanningDialogOpen(true);
	}

	const handlePlanningConfirmed = () => {
		event.days.forEach(d => {
			if (d.state !== 'active') d.activate();
		});
		setIsConfirmPlanningDialogOpen(false);
		setActiveStep(1);	
	}

	const handlePlanningDenied = () => {
		setIsConfirmPlanningDialogOpen(false);
	}

	const handelShowRegistrations = () => {
		setShowGetStartedFlow(false);
	}

	return (
		<>
			<Box className="page-content">
				<Container maxWidth="md">
					<Box display="flex" className="page-title">
						<Box flexGrow={1}>
							<Typography variant="h4">
								Bijeenkomst beheren
							</Typography>
						</Box>
						<Box alignSelf="flex-end">
							<Link component={RouterLink} to='/my/events'>Mijn bijeenskomsten</Link>
						</Box>
					</Box>
				</Container>
			</Box>

			{ /* DETAILS */ }
			<Box className="section section-highlight">
				<Container maxWidth="md">
					<EventSummary event={event} />
				</Container>
			</Box>

			{ /* STEPPER TO COMPLETE NEW EVENTS */ }
			{showGetStartedFlow && 
				<Box className="section section">
					<Container maxWidth="md">
						<Stepper activeStep={activeStep} orientation="vertical">
							<Step>
								<StepLabel>Plan je bijeenkomst</StepLabel>
								<StepContent>
									{event.days.length === 0 && 
										<Button variant="contained" color="primary" onClick={handleAddEventDay}>
											Maak tijdsblokken
										</Button>
									}
									{event.days.length > 0 && 
										<>	
											<MyEventDaysForNewEvent event={event} onEditEventDay={handleEditEventDay}/>
											<Button variant="outlined" color="primary" onClick={handleAddEventDay} className={clsx(classes.marginRight, classes.marginTop)}>
												Extra dag toevoegen
											</Button>
											<Button variant="contained" color="primary" onClick={handleStartPlanningConfirmation} className={clsx(classes.marginRight, classes.marginTop)}>
												Planning gereed
											</Button>
											<Dialog open={isConfirmPlanningDialogOpen}>
												<DialogTitle>
													Bevestiging planning
												</DialogTitle>
												<DialogContent>
													<DialogContentText>Na akkoord kunnen de tijdsblokken niet meer gewijzigd worden. Dagen toevoegen blijft mogelijk. Is de planning akkoord?</DialogContentText>
												</DialogContent>
												<DialogActions>
													<Button color="primary" onClick={handlePlanningDenied}>Planning wijzigen</Button>
													<Button variant="contained" color="primary" onClick={handlePlanningConfirmed}>Planning bevestigen</Button>
												</DialogActions>
											</Dialog>
										</>
									}
								</StepContent>
							</Step>
							<Step>
								<StepLabel>Verstuur de uitnodigingen</StepLabel>
								<StepContent>
									{event.hasActiveDays &&
										<>
											<Typography gutterBottom>
												Geef onderstaande link aan de genodigden, zodat ze zelf een tijdsblok kunnen reserveren.
											</Typography>
											<EventShareLink eventId={event.id!} />
										</>
									}
									<Button variant="contained" color="primary" onClick={handelShowRegistrations} className={classes.marginTop}>Bekijk de aanmeldingen</Button>
								</StepContent>
							</Step>
						</Stepper>
					</Container>
				</Box>
			}
			{!showGetStartedFlow &&
				<>
					{ /* SEND INVITATION */ }
					<Box className="section">
						<Container maxWidth="md">
							<Typography variant="h5" gutterBottom>
								Uitnodigingen versturen
							</Typography>
							{!event.hasActiveDays &&
								<>
									<Typography></Typography>
								</>}
							{event.hasActiveDays &&
								<>
									<Typography gutterBottom>
										Geef onderstaande link aan de genodigden, zodat ze zelf een tijdsblok kunnen reserveren.
									</Typography>
									<EventShareLink eventId={event.id!} />
								</>
							}
						</Container>
					</Box>

					{/* PLANNING */ }
					<Box className="section section-highlight">
						<Container maxWidth="md">
							<MyEventDays event={event} onAddEventDayClick={handleAddEventDay} onEditEventDayClick={handleEditEventDay} />
						</Container>
					</Box>
				</>
			}

			<AddDayDialog 
					open={isAddDayDialogOpen} 
					initValues={editEventDay?.configuration}
					title={editEventDay ? 'Tijdsblokken wijzigen' : 'Tijdsblokken toevoegen'}
					submitButtonText={editEventDay ? 'Wijzigingen opslaan' : 'Tijdsblokken toevoegen'}
					onClose={handleAddDayDialogClose}
					onSubmit={handleSubmitEventDay}
					disabledDatesInMillis={editEventDay !== undefined
						? _.map(_.filter(eventDayStore.days, d => d.id !== editEventDay.id), d => d.date.toMillis()) // exclude day of edit event day
						: _.map(eventDayStore.days, d => d.date.toMillis())} 
					/>
		</>
	);
})));


