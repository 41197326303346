import { createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { inject, observer } from "mobx-react";
import React from 'react';
import Event from '../models/Event';
import RootStore from '../stores/RootStore';

const styles = (theme: Theme): any => createStyles({
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
	event?: Event
}


@inject('rootStore')
@observer
class EventSummary extends React.Component<IProps, {}> {

	render() {
		if (!this.props.event) return;
		//const { classes } = this.props;
		return (
			<>
				<Typography variant="h6">
					{this.props.event!.title}
				</Typography>
				<Typography
					variant="subtitle1">
					{this.props.event!.description}
				</Typography>
				{/* <Typography
					variant="subtitle1">
					Datum: {dateUtils.format(this.props.event!.date!, 'PPPP')}
				</Typography>
				<Typography>
					Tijdstip: {dateUtils.format(this.props.event!.start!, 'HH:mm')} tot {dateUtils.format(this.props.event!.end!, 'HH:mm')}
				</Typography> */}
				<Typography>
					Locatie: {this.props.event!.location}
				</Typography>
			</>
		);
	}
}

export default withStyles(styles)(EventSummary);