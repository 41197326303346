import emailValidator from 'email-validator';
import { action, computed, observable } from "mobx";
import ParticipationStore from "../stores/ParticipationStore";
const { v4: uuidv4 } = require('uuid');


export default class Participation {
	store: ParticipationStore;

	@observable id: string;
	@observable eventId: string = '';
	@observable eventDayId: string | undefined = undefined;
	@observable timeBlockId: string | undefined = undefined;
	@observable name: string = '';
	@observable email: string = '';
	@observable numberOfAdults: number | undefined = undefined;
	@observable numberOfChildren: number | undefined = undefined;

	constructor(store: ParticipationStore, id: string = uuidv4()) {
		this.store = store;
		this.id = id;
	}

	@computed get emailValid(): boolean {
		return emailValidator.validate(this.email);
	}

	@computed get nameValid(): boolean {
		return this.name !== '';
	}

	@action setEmail = (value: string) => { this.email = value; }
	@action setName = (value: string) => { this.name = value; }
	@action setEventDay = (id: string | undefined) => { this.eventDayId = id; }
	@action setTimeBlock = (id: string | undefined) => { this.timeBlockId = id; }
	@action setNumberOfAdults = (value: number) => { this.numberOfAdults = value; }
	@action setNumberOfChildren = (value: number) => { this.numberOfChildren = value; }

	@action updateFromServer = (data: any) => {
		this.id = data._id;
		this.eventId = data.eventId;
		this.timeBlockId = data.timeBlockId;
		this.name = data.name;
		this.email = data.email;
		this.numberOfAdults = data.numberOfAdults;
		this.numberOfChildren = data.numberOfChildren;
	}

	save = async () => {
		await this.store.transportLayer.saveRegistration(this);
	}
}
