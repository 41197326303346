import { Box, Container, createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import RootStore from '../../stores/RootStore';


const styles = (theme: Theme): any => createStyles({
	signinSection: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10)
	},
	field: {
		marginBottom: theme.spacing(1)
	},
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
}


@inject('rootStore')
@observer
class PrivacyStatement extends React.Component<IProps, {}> {


	render() {
		//const { classes } = this.props;
		return (
			<>
				<Box className={`page-content`}>
					<Box className={`section`}>
						<Container maxWidth="md">
							<Typography variant="h4">Privacy statement</Typography>
						</Container>
					</Box>
					<Box>
						<Container maxWidth="md">
							<Typography paragraph={true}>
								Wij geven om uw privicay. We versturen uw prive gegevens alleen naar derde partijen als wet- en regelgeving ons hiertoe verplicht,
								wanneer het vereist is om de werking van een feature te corrigeren, of wanneer de data compleet annoniem is.
							</Typography>
							<Typography paragraph={true}>
								We gebruiken gebruikelijke tools om performence en gebruik te annalyseren. De technieken en tools staan uitgelicht in dit document.
							</Typography>
							<Typography paragraph={true}>
								Als u het niet eens bent met onze Privacy Statement, willen we graag de reden weten zodat wij hiervan kunnen leren en mogelijk onze
								strategy kunnen aanpassen. Als u het helaas niet eens bent met onze Privacy Statement dient u onze diensten niet te gebruiken.
							</Typography>
							<Typography variant="h5">Welke persoonlijke data verzamelen we</Typography>
							<Typography paragraph={true}>
								Onze diensten kunnen we alleen aanbieden door enige persoonlijke data te vragen. Van alle gebruikers, geregistreerd als zowel genodigden
								voor je event, slaan we de naam en email op. Als je een adres ingeeft bij het veld "locatie" van een event, slaan we ook dit op.
							</Typography>
							<Typography paragraph={true}>
								Andere informatie die we (tijdelijk) opslaan zijn jouw IP address, je browser gegevens, taalvoorkeuren, het merk van je mobiele telefoon etc.
								Sommige data wordt gebruikt voor statistische analyses. Niet alle informatie wordt direct door ons gebruikt. Sommige informatie is noodzakelijke
								vanwege technische doeleinden om zo bijvoorbeeld een correcte werking van de dienstverlening op jouw device te kunnen aanbieden.
							</Typography>
							<Typography paragraph={true}>
								Als je jouw data wilt verwijderen, neem dan contact op met onze service desk.
							</Typography>

							{/* <Typography variant="h5">Advertenties</Typography>
							<Typography paragraph={true}>
								We 
							</Typography> */}

							<Typography variant="h5">Welke data wordt gedeelt met anderen</Typography>
							<Typography paragraph={true}>
								We verkopen of overhandigen geen persoonlijke data aan anderen. Als we data delen is dit om aan de Nederlandse wet- en regelgeving te voldoen.
							</Typography>

							<Typography variant="h5">Hoe gebruiken we Social Media</Typography>
							<Typography paragraph={true}>
								We bieden geen login services aan via Social Media platformen. Ook bieden we geen mogelijkheden aan om je event te delen op Social Media.
							</Typography>

							<Typography variant="h5">Minimale vereiste leeftijd</Typography>
							<Typography paragraph={true}>
								Omdat we niet eenvoudig een minimale leeftijd kunnen controleren via integraties is de minimale leeftijd voor het gebruik van onze diensten 18 jaar.
								Mensen van 17 jaar en jonger kunnen onze diensten alleen gebruiken onder toezicht van ouders of voogd.
							</Typography>

							<Typography variant="h5">Hoe kan je de persoonlijke data beheren die we van je hebben</Typography>
							<Typography paragraph={true}>
								Je hebt rechten om alle data in te zien die we van je hebben. Je kan een overzicht opvragen door contact op te nemen met onze service desk.
							</Typography>

							<Typography variant="h5">Wie verwerkt je data</Typography>
							<Typography paragraph={true}>
								Tijdprikker.nl, gelocaliseerd in Rijswijk, Nederland, beheert en verwerkt je persoonlijke data in hun platform.
							</Typography>

							<Typography variant="h5">Session Recording</Typography>
							<Typography paragraph={true}>
								Om beter te begrijpen hoe ons product gebruikt wordt, kunnen we verschillende technieken inzetten. Het is niet gezegd dat deze technieken zijn
								ingeschakeld voor jou. Een van deze technieken is "Session Recording". Hierdoor verkrijgen we Heatmaps en geannonimiseerde opnames van hoe onze
								bezoekers ons product gebruiken. Dit stelt ons in staat de performance van features te analyseren. Ze helpen ons complexe features en ontbrekende
								features te identificeren.
							</Typography>
						</Container>
					</Box>
				</Box>
			</>
		);
	}
}

export default withStyles(styles)(PrivacyStatement);
