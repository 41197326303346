import { action, observable } from 'mobx';
import Participation from '../models/Participation';
import TransportLayer from './TransportLayer';

export default class ParticipationStore {
	transportLayer: TransportLayer;
	@observable participations: Participation[] = [];

	constructor(transportLayer: TransportLayer) {
		this.transportLayer = transportLayer;
	}

	@action clear = () => {
		this.participations = [];
	}

	@action create = (eventId: string): Participation => {
		let p = new Participation(this);
		p.eventId = eventId;
		this.participations.push(p);
		return p;
	}
}