import { Box, Container, createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import RootStore from '../../stores/RootStore';


const styles = (theme: Theme): any => createStyles({
	signinSection: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10)
	},
	field: {
		marginBottom: theme.spacing(1)
	},
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
}


@inject('rootStore')
@observer
class Contact extends React.Component<IProps, {}> {


	render() {
		//const { classes } = this.props;
		return (
			<>
				<Box className={`page-content`}>
					<Container maxWidth="md">
						<Box className="page-title">
							<Typography variant="h4">Contact</Typography>
						</Box>
					</Container>
				</Box>
				<Box className={`section`}>
					<Container maxWidth="md">
						<Typography>
							info@tijdprikker.nl
						</Typography>
					</Container>
				</Box>
			</>
		);
	}
}

export default withStyles(styles)(Contact);
