import { Box, Container, createStyles, Link, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, WithStyles, withStyles, withTheme } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import RootStore from '../../stores/RootStore';

const useStyles = makeStyles((theme) => ({
	yes: {
		color: theme.palette.success.main
	},
	no: {
		color: theme.palette.error.main
	}
}));

const CookiePolicy = withTheme(() => {
	const classes = useStyles();

	return (
		<>
			<Box className={`page-content`}>
				<Container maxWidth="md">
					<Box className="page-title">
						<Typography variant="h4" component="h1">Cookiebeleid</Typography>
					</Box>
				</Container>
			</Box>
			<Box className={`section`}>
				<Container maxWidth="md">
					<Typography paragraph={true}>
						Voor onze website gebruiken wij verschillende soorten cookies. Wij vinden het belangrijk dat je zelf controle 
						hebt over jouw online privacy. Daarom zijn we duidelijk over welke cookies we gebruiken en waarvoor we ze gebruiken.
					</Typography>

					<Typography variant="h5" component="h2">Wat zijn cookies?</Typography>
					<Typography paragraph={true}>
						Cookies zijn kleine tekstbestanden die een website op je computer, tablet of mobiele telefoon plaatst op het moment 
						dat je de website bezoekt. In deze cookies wordt informatie over jouw websitebezoek opgeslagen. Met cookies kan een 
						website je bijvoorbeeld herkennen bij een volgend bezoek. Dat is handig als je niet telkens opnieuw wilt inloggen.
					</Typography>

					<Typography variant="h5" component="h2">Welke cookies gebruiken wij?</Typography>
					<Typography paragraph={true}>
						<Table aria-label="Mijn georganiseerde bijeenkomsten">
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell>Standaard en bij niet akkoord</TableCell>
									<TableCell>Bij akkoord</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>Functionele cookies</TableCell>
									<TableCell className={classes.yes}>ja</TableCell>
									<TableCell className={classes.yes}>ja</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Analytische cookies</TableCell>
									<TableCell className={classes.yes}>ja</TableCell>
									<TableCell className={classes.yes}>ja</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Optimalisatie cookies</TableCell>
									<TableCell className={classes.no}>nee</TableCell>
									<TableCell className={classes.yes}>ja</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Advertentie cookies</TableCell>
									<TableCell className={classes.no}>nee</TableCell>
									<TableCell className={classes.yes}>ja</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Typography>

					<Typography variant="h6" component="h3">Functionele cookies</Typography>
					<Typography paragraph={true}>
						Functionele cookies plaatsen we altijd en zijn nodig om de website goed te laten werken. Deze cookies hebben weinig 
						impact op je privacy en worden anoniem ingezet. Zo kunnen wij  onze online dienstverlening uitvoeren. Ze zorgen er 
						bijvoorbeeld voor dat je ingelogd kunt blijven op de website wanneer je dat wilt.
						{/* Met de cookies van Live Chat en 
						Zendesk zorgen wij ervoor dat je ons via de chatfunctie een vraag kunt stellen. */}
					</Typography>

					<Typography variant="h6" component="h3">Niet-functionele cookies</Typography>
					<Typography paragraph={true}>
						Naast functionele cookies plaatsen we ook standaard analytische cookies. Dat zijn niet-functionele cookies, omdat ze 
						niet nodig zijn om de website goed te laten werken.
					</Typography>
					<Typography paragraph={true}>
						Pas wanneer je akkoord gaat met het plaatsen van cookies, plaatsen we ook een aantal andere niet-functionele cookies.
						Deze hebben allemaal verschillende doeleinden en zorgen ervoor dat wij onze dienstverlening kunnen verbeteren. Zo 
						gebruiken we cookies om op onze website online survey's te tonen, verbeteringen op onze website te testen, social
						media buttons te tonen of om relevante advertenties voor onze diensten te kunnen tonen op andere websites.
					</Typography>
					
					<Typography variant="h5" component="h2">Analytische cookies</Typography>
					<Typography paragraph={true}>
						Met analytische cookies kunnen we het websitebezoek meten: bijvoorbeeld hoe vaak onze site wordt bezocht en naar welke 
						informatie bezoekers zoeken. Zo weten we welke delen van de site populair zijn en hoe we onze website kunnen verbeteren. 
						De statistieken die we verzamelen kunnen we niet herleiden naar personen. Deze gegevens worden na verzameling anoniem gemaakt.
					</Typography>
					<Typography paragraph={true}>
						We willen weten of wijzigingen die we doen aan de website ook verbeteringen zijn. Daarom voeren we verbeteringen vaak eerst 
						uit in een A/B-test: bezoekers zien dan op hetzelfde moment een aangepaste versie van de website. Cookies zorgen ervoor dat 
						je de juiste versie van een pagina ziet en meten de resultaten. Zo optimaliseren we steeds de website.
					</Typography>
					<Typography paragraph={true}>
						Met Google Analytics kunnen we de data beter begrijpen. De analytische cookies plaatsen we altijd bij een bezoek aan onze website.
					</Typography>
					<Typography paragraph={true}>
						Met analytische cookies verzamelen we onder andere de volgende gegevens:
					</Typography>
					<ul>
						<li>Het aantal bezoekers op onze webpagina's</li>
						<li>Hoelang een bezoeker op onze site is.</li>
						<li>Gegevens over je browser en apparaat waarmee je onze website bezoekt, zoals besturingssysteem en schermgrootte.</li>
						<li>Hoe je op onze website terecht bent gekomen (bijvoorbeeld omdat je op een advertentie van ons hebt geklikt, of op een link in een e-mail).</li>
						<li>De snelheid van onze website om je browser te kunnen meten. Met deze informatie kunnen we mogelijke problemen met de prestaties van onze website op tijd ontdekken en oplossen.</li>
					</ul>
					<Typography variant="h6" component="h3">Privacy</Typography>
					<Typography paragraph={true}>
						Meer informatie over wat Adobe en Google met de verzamelde gegevens doen, lees je in de <Link href="https://support.google.com/analytics/answer/6004245?hl=nl">privacyverklaring van Google</Link>. Deze verklaringen kunnen regelmatig wijzigen.
					</Typography>
					<Typography paragraph={true}>
						Wij geven met analytische cookies nooit jouw persoonsgegevens (zoals naam, adres, BSN en telefoonnummer) door aan Adobe of Google. In onze privacyverklaring lees je hoe wij omgaan met persoonsgegevens.
					</Typography>

					<Typography variant="h5" component="h2">Optimalisatie cookies</Typography>
					<Typography paragraph={true}>
						Er worden momenteel nog geen optimalisatie cookies geplaatst.
					</Typography>

					<Typography variant="h5" component="h2">Advertentie cookies</Typography>
					<Typography paragraph={true}>
						Er worden momenteel nog geen advertentie cookies geplaatst.
					</Typography>
				</Container>
			</Box>
		</>
	);
});

export { CookiePolicy }
