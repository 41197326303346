import React, { useEffect } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, IconButton, InputAdornment, OutlinedInput, Radio, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme, withTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Calendar, KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { IEventDayConfiguration } from '../../../models/EventDay';
import { TimeBlocksPreview } from '../../../components/TimeBlocksPreview';
import { useState } from 'react';
import {  DateUtils } from '../../../lib/DateUtils';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import CloseIcon from '@material-ui/icons/Close';
import StepSummary from './AddDayDialogStepSummary';
import _ from 'lodash';
import { AirlineSeatIndividualSuiteSharp } from '@material-ui/icons';
import { validateLocaleAndSetLanguage } from 'typescript';
  
interface ValidationState {
    date: boolean | undefined;
    start: boolean | undefined;
    end: boolean | undefined;
    timeBlockDuration: boolean | undefined;
    timeBlockMeantimeDuration: boolean | undefined;
    timeBlockCount: boolean | undefined;
    simultaneGroupCount: boolean | undefined;
    totalGroupCount: boolean | undefined;
    timeBlockDurationMode: boolean | undefined;
    timeBlockCountMode: boolean | undefined;
    calculation: boolean | undefined;
    messages: string[];
    calculatedEndError: boolean,
    calculatedTimeBlockDurationError: boolean,
    calculatedPauseError: boolean,
    steps: boolean[];
}

const useStyles = makeStyles((theme) => ({
    caption: {
        fontWeight: 'bold',
        marginTop: theme.spacing(2)
    },
    stepBody: {
        //padding: theme.spacing(2)
    },
    infoBox: {
        padding: theme.spacing(2)
    },
    margin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2) 
    },
    marginTop: {
        marginTop: theme.spacing(2),
    },
    marginBottom: {
        marginBottom: theme.spacing(2) 
    },
    textField: {
        width: '100%'
    },
    optionalControlPanel: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

interface IProps {
    title?: string;
    submitButtonText?: string;
    onClose?: () => void;
    onSubmit?: (config: IEventDayConfiguration) => void;
    open: boolean;
    initValues?: IEventDayConfiguration | undefined;
    disabledDatesInMillis?: number[];
}

export default withTheme(observer((props: IProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const maxSteps = 7;
    const [activeStep, setActiveStep] = useState(0);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [values, setValues] = useState<IEventDayConfiguration>({
        date: DateTime.now(),
        start: undefined,
        end: undefined,
        timeBlockDuration: undefined,
        timeBlockMeantimeDuration: undefined,
        timeBlockCount: undefined,
        simultaneGroupCount: undefined,
        totalGroupCount: undefined,
        timeBlockDurationMode: 'calculated',
        timeBlockCountMode: 'total-groups'
    });
    const [validations, setValidations] = useState<ValidationState>({
        date: undefined,
        start: undefined,
        end: undefined,
        timeBlockDuration: undefined,
        timeBlockMeantimeDuration: undefined,
        timeBlockCount: undefined,
        simultaneGroupCount: undefined,
        totalGroupCount: undefined,
        timeBlockDurationMode: true,
        timeBlockCountMode: true,
        calculation: undefined,
        messages: [],
        calculatedEndError: false,
        calculatedTimeBlockDurationError: false,
        calculatedPauseError: false,
        steps: [false, false, false, false, false, false]
    });
    const [calculatedValues, setCalculatedValues] = useState<IEventDayConfiguration>(values);

    // update state with props values
    useEffect(() => {
        clear();
        let v: IEventDayConfiguration = props.initValues 
            ? props.initValues!
            : {
                date: DateUtils.dateOnly(DateTime.now()),
                start: undefined,
                end: undefined,
                timeBlockDuration: undefined,
                timeBlockMeantimeDuration: undefined,
                timeBlockCount: undefined,
                simultaneGroupCount: undefined,
                totalGroupCount: undefined,
                timeBlockDurationMode: 'calculated',
                timeBlockCountMode: 'total-groups'
            };
        setValues(v);
        setCalculatedValues(v);
    }, [props.initValues]);

    // Step validation
    useEffect(() => {
        setNextEnabled(validate(false));
    }, [activeStep]);

    // form validation
    useEffect(() => {
        setNextEnabled(validate());
    }, [values])

    const validate = (updateValidationState: boolean = true) => {
        switch (activeStep) {
            // Date
            case 0:
                const date = values.date ? true : false;
                if (updateValidationState) setValidations({...validations, date });
                return date;
            
            // Simultane group count
            case 1:
                const simultaneGroupCount = values.simultaneGroupCount !== undefined && values.simultaneGroupCount! >= 1; 
                if (updateValidationState) setValidations({...validations, simultaneGroupCount });
                return simultaneGroupCount;
            
            // Pause
            case 2:
                const timeBlockMeantimeDuration = values.timeBlockMeantimeDuration !== undefined && values.timeBlockMeantimeDuration >= 0;
                if (updateValidationState) setValidations({...validations, timeBlockMeantimeDuration });
                return timeBlockMeantimeDuration;

            // Start
            case 3:
                const start = !values.start || !values.start.isValid ? false : true;
                if (updateValidationState) setValidations({...validations, start});
                return start;

             // Time Block Count
             case 4:
                const timeBlockCountMode = values.timeBlockCountMode !== undefined;
                const totalGroupCount = values.timeBlockCountMode === 'total-groups' && values.totalGroupCount !== undefined && values.totalGroupCount >= 1;
                const timeBlockCount = values.timeBlockCountMode === 'fixed' && values.timeBlockCount !== undefined && values.timeBlockCount >= 1;
                
                if (updateValidationState) setValidations({...validations, totalGroupCount, timeBlockCount });

                return timeBlockCountMode &&
                    ((values.timeBlockCountMode === 'total-groups' && totalGroupCount) 
                    || (values.timeBlockCountMode === 'fixed' && timeBlockCount));

            // Time Block Duration
            case 5:
                let timeBlockDurationMode = values.timeBlockDurationMode !== undefined;
                const end = values.timeBlockDurationMode == 'calculated' && values.end !== undefined && values.end > values.start!;
                const timeBlockDuration = values.timeBlockDurationMode == 'fixed' && values.timeBlockDuration !== undefined && values.timeBlockDuration >= 1;

                if (updateValidationState) setValidations({...validations, end, timeBlockDuration });

                return timeBlockDurationMode &&
                    ((values.timeBlockDurationMode === 'calculated' && values.end !== undefined && end) 
                    || (values.timeBlockDurationMode === 'fixed' && values.timeBlockDuration !== undefined && timeBlockDuration))

            // Complete
            case 6:
                return validations.messages.length === 0;

            default:
                return true;
        }
    }

    // Validate calculation
    useEffect(() => {
        const minTimeBlockDuration = 1;
        let messages: string[] = [];
        let calculatedEndError = false;
        let calculatedTimeBlockDurationError = false;
        let calculatedPauseError = false;

        let totalDayDuration = calculatedValues.start && calculatedValues.start.isValid && calculatedValues.end && calculatedValues.end.isValid 
            ? calculatedValues.end!.diff(calculatedValues.start!, 'minutes').minutes
            : undefined;

        if (calculatedValues.start !== undefined && calculatedValues.start.isValid && calculatedValues.end !== undefined && calculatedValues.end.isValid && calculatedValues.end.diff(calculatedValues.start.set({hour:0, minute:0, second:0, millisecond: 0}), 'days').days > 1) {
            messages.push('De eindtijd is na 00:00 middernacht (verklein het aantal blokken of de duur van een blok).');
            calculatedEndError = true;
        }

        if (calculatedValues.timeBlockDuration !== undefined && calculatedValues.timeBlockDuration! < minTimeBlockDuration) {
            messages.push(`De duur van een tijdsblok moet minimaal ${minTimeBlockDuration} minuten zijn.`);
            calculatedTimeBlockDurationError = true;
        }

        if (calculatedValues.timeBlockCount !== undefined && calculatedValues.start && totalDayDuration && calculatedValues.timeBlockMeantimeDuration && calculatedValues.timeBlockMeantimeDuration! > 0
            && (calculatedValues.timeBlockMeantimeDuration * (calculatedValues.timeBlockCount! - 1) + calculatedValues.timeBlockCount! * minTimeBlockDuration) > calculatedValues.end!.diff(calculatedValues.start!, 'minutes').minutes) {
                                
            let totalPauseBlocks = calculatedValues.timeBlockCount! - 1;
            let totalMinimalTimeBlockDuration = calculatedValues.timeBlockCount! * minTimeBlockDuration
            let maxPauseTime = Math.max(0, Math.floor((totalDayDuration - totalMinimalTimeBlockDuration) / totalPauseBlocks));
            messages.push(`Aantal tijdsblokken passen niet tussen begin- en eindtijd (maak de pauze maximaal ${maxPauseTime} minuten, start eerder, eindig later of maak de tijdsblokken korter).`);
            calculatedPauseError = true;
        }

        setValidations({...validations, messages, calculatedEndError, calculatedTimeBlockDurationError, calculatedPauseError });
    }, [calculatedValues])

    // calculation
    useEffect(() => {
        let calculated: IEventDayConfiguration = {
            date: values.date,
            start: values.start,
            timeBlockMeantimeDuration: values.timeBlockMeantimeDuration,
            timeBlockDurationMode: values.timeBlockDurationMode,
            timeBlockCountMode: values.timeBlockCountMode,
            simultaneGroupCount: values.simultaneGroupCount,

            // values depending no chosen option
            end: undefined,
            timeBlockDuration: undefined,
            timeBlockCount: undefined,
            totalGroupCount: undefined,
        };

        //console.log('###', calculated.start);

        // Calculate values which depend on both options.
        if (calculated.timeBlockDurationMode === 'fixed' && calculated.timeBlockCountMode === 'fixed') {
            calculated.timeBlockDuration = values.timeBlockDuration;
            calculated.timeBlockCount = values.timeBlockCount;
            calculated.totalGroupCount = values.timeBlockCount !== undefined && values.simultaneGroupCount !== undefined 
                ? values.timeBlockCount * values.simultaneGroupCount 
                : undefined;

            calculated.end = calculated.start && calculated.start.isValid && calculated.timeBlockCount !== undefined && calculated.timeBlockDuration !== undefined && calculated.timeBlockMeantimeDuration !== undefined
                ? calculated.start.plus({ minutes: calculated.timeBlockCount * calculated.timeBlockDuration + (calculated.timeBlockCount - 1) * calculated.timeBlockMeantimeDuration})
                : undefined;

        } else if (values.timeBlockDurationMode === 'fixed' && values.timeBlockCountMode === 'total-groups') {
            calculated.timeBlockDuration = values.timeBlockDuration;
            calculated.totalGroupCount = values.totalGroupCount;
            calculated.timeBlockCount = values.totalGroupCount && calculated.simultaneGroupCount && calculated.simultaneGroupCount > 0
                ? Math.ceil(values.totalGroupCount / calculated.simultaneGroupCount)
                : undefined;

            calculated.end = calculated.start && calculated.start.isValid && calculated.timeBlockCount !== undefined && calculated.timeBlockDuration !== undefined && calculated.timeBlockMeantimeDuration !== undefined
                ? calculated.start.plus({ minutes: calculated.timeBlockCount * calculated.timeBlockDuration + (calculated.timeBlockCount - 1) * calculated.timeBlockMeantimeDuration})
                : undefined;

            // recalculate total group count: total group count can be higher that the provided value.
            calculated.totalGroupCount = calculated.timeBlockCount !== undefined && calculated.simultaneGroupCount !== undefined
                ? calculated.timeBlockCount * calculated.simultaneGroupCount
                : undefined;

        } else if (calculated.timeBlockDurationMode === 'calculated' && calculated.timeBlockCountMode === 'fixed') {
            calculated.end = values.end;
            calculated.timeBlockCount = values.timeBlockCount;
            calculated.totalGroupCount = calculated.timeBlockCount && calculated.simultaneGroupCount
                ? calculated.timeBlockCount * calculated.simultaneGroupCount 
                : undefined;

            calculated.timeBlockDuration = calculated.start && calculated.start.isValid && calculated.end && calculated.end.isValid && calculated.timeBlockCount !== undefined && calculated.timeBlockCount > 0 && calculated.timeBlockMeantimeDuration !== undefined
                ? Math.floor( (calculated.end.diff(calculated.start, 'minutes').minutes - ((calculated.timeBlockCount - 1) * calculated.timeBlockMeantimeDuration) ) / calculated.timeBlockCount)
                : undefined;

            // recalculate end: timeblock durations are floored. Event can end earlier than the provided time.
            calculated.end = calculated.start && calculated.start.isValid && calculated.timeBlockCount !== undefined && calculated.timeBlockDuration !== undefined && calculated.timeBlockMeantimeDuration !== undefined
                ? calculated.start.plus({ minutes: calculated.timeBlockCount * calculated.timeBlockDuration + (calculated.timeBlockCount - 1) * calculated.timeBlockMeantimeDuration})
                : undefined;

        } else if (calculated.timeBlockDurationMode === 'calculated' && calculated.timeBlockCountMode === 'total-groups') {
            calculated.end = values.end;
            calculated.timeBlockCount = values.totalGroupCount !== undefined && calculated.simultaneGroupCount !== undefined && calculated.simultaneGroupCount > 0
                ? Math.ceil(values.totalGroupCount / calculated.simultaneGroupCount)
                : undefined;
            calculated.totalGroupCount = calculated.timeBlockCount !== undefined && calculated.simultaneGroupCount !== undefined
                ? calculated.timeBlockCount * calculated.simultaneGroupCount 
                : undefined;

            calculated.timeBlockDuration = calculated.start && calculated.start.isValid && calculated.end && calculated.end.isValid && calculated.timeBlockCount !== undefined && calculated.timeBlockCount > 0 && calculated.timeBlockMeantimeDuration !== undefined
                ? Math.floor((calculated.end.diff(calculated.start, 'minutes').minutes - (Math.max(0, calculated.timeBlockCount - 1) * calculated.timeBlockMeantimeDuration)) / calculated.timeBlockCount)
                : undefined;

            // recalculate end: timeblock durations are floored. Event can end earlier than the provided time.
            calculated.end = calculated.start !== undefined && calculated.start.isValid && calculated.timeBlockCount !== undefined && calculated.timeBlockDuration !== undefined && calculated.timeBlockMeantimeDuration !== undefined
                ? calculated.start.plus({ minutes: calculated.timeBlockCount * calculated.timeBlockDuration + (Math.max(0, calculated.timeBlockCount - 1)) * calculated.timeBlockMeantimeDuration})
                : undefined;
        }
        
        setCalculatedValues(calculated);
    }, [values])

    const clear = () => {
        setActiveStep(0);
        setValues({
            date: DateUtils.dateOnly(DateTime.now()),
            start: undefined,
            end: undefined,
            timeBlockDuration: undefined,
            timeBlockMeantimeDuration: undefined,
            timeBlockCount: undefined,
            simultaneGroupCount: undefined,
            totalGroupCount: undefined,
            timeBlockCountMode: 'total-groups',
            timeBlockDurationMode: 'calculated'
        });
    }    
    
    const handleClose = () => {
        if (props.onClose) props.onClose();
        clear();
    };

    const handleSubmit = () => {
        if (props.onSubmit) props.onSubmit(calculatedValues!);
        clear();
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleDateChange = (date: MaterialUiPickersDate, isFinish?: boolean) => {
        let d = date 
            ? DateUtils.dateOnly(DateTime.fromJSDate(date))
            : DateTime.invalid(' ');

        // Update start and end date part with info from date.
        let start = d.isValid && values.start && values.start?.isValid ? values.start.set({year: d.year, month: d.month, day: d.day}) : values.start;
        let end = d.isValid && values.end && values.end.isValid ? values.end.set({year: d.year, month: d.month, day: d.day}) : values.end;

        setValues({...values, date: d, start, end});
    }

    const handleNumberChange = (prop: keyof IEventDayConfiguration) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({...values, [prop]: +event.target.value});
    }

    const handleTimeChange = (prop: keyof IEventDayConfiguration) => (date: MaterialUiPickersDate, value: string | null | undefined) => {
        let d = date ? DateTime.fromJSDate(date) : DateTime.invalid(' ');

        // Update date part with infor from values.date
        if (d.isValid && values.date?.isValid) {
            d = d.set({year: values.date?.year, month: values.date?.month, day: values.date?.day});
        }
        setValues({...values, [prop]: d});
    }

    const handleTimeBlockDurationMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({...values, timeBlockDurationMode: event.currentTarget.value as 'fixed' | 'calculated'});
    };

    const handleTimeBlockCountMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({...values, timeBlockCountMode: event.currentTarget.value as 'fixed' | 'total-groups'});
    };

    const shouldDisableDate = (muiDate: MaterialUiPickersDate) : boolean => {
        if (props.disabledDatesInMillis && muiDate) {
            const d = DateTime.fromJSDate(muiDate);
            return _.includes(props.disabledDatesInMillis, d.toMillis());
        }
        return false;
    }
    
    return (
        <Dialog fullScreen={fullScreen} onClose={handleClose} open={props.open} aria-labelledby="add-day-dialog" maxWidth="sm" fullWidth={true} scroll="paper" disableBackdropClick >
            <DialogTitle>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box flexGrow={1}>
                        {props.title ? props.title : 'Dag Toevoegen'}
                    </Box>
                    <Box>
                        <IconButton aria-label="close" color="primary" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogActions style={{padding:0}}>
                <Box flexGrow={1}>
                    <StepSummary values={calculatedValues} messages={validations.messages} 
                        endError={validations.calculatedEndError}
                        blockDurationError={validations.calculatedTimeBlockDurationError}
                        pauseError={validations.calculatedPauseError}
                    />
                </Box>
            </DialogActions>
            <DialogContent dividers={true}>
                <Box>
                    {activeStep === 0 && (
                        <>
                            <Box className={classes.stepBody} style={{overflow:'hidden'}}>
                                <Typography variant="h6" className={classes.caption}>Wanneer is je bijeenkomst?</Typography>
                                <Typography>Als je bijeenkomst uit meerdere dagen bestaat dien je alle dagen afzonderlijk toe te voegen.</Typography>
                                <Calendar 
                                    date={values.date.toJSDate()}
                                    onChange={handleDateChange}
                                    disablePast={true}
                                    shouldDisableDate={shouldDisableDate} />
                            </Box>
                        </>
                    )}
                    {activeStep === 1 && (
                        <>
                            <Typography variant="h6" className={classes.caption}>Hoeveel huishoudens kun je per tijdsblok ontvangen?</Typography>
                            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined"
                                error={validations.simultaneGroupCount !== undefined && !validations.simultaneGroupCount}>
                                <OutlinedInput
                                    id="outlined-adornment-simultanegroupcount"
                                    type="number"
                                    defaultValue={values.simultaneGroupCount}
                                    onChange={handleNumberChange('simultaneGroupCount')}
                                    aria-describedby="outlined-simultanegroupcount-helper-text"
                                    required
                                    inputProps={{
                                        'aria-label': 'simultane group count',
                                        min: 1
                                    }}
                                />
                                {validations.simultaneGroupCount !== undefined && !validations.simultaneGroupCount && <FormHelperText color="error.main" id="filled-weight-helper-text">U moet ten minste 1 huishouden tegelijkertijd kunnen ontvangen.</FormHelperText>}
                            </FormControl>
                        </>
                    )}
                    {activeStep == 2 && (
                        <>
                            <Typography variant="h6" className={classes.caption}>Hoeveel pauze wil je tussen de tijdsblokken?</Typography>
                            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined"
                                error={validations.timeBlockMeantimeDuration !== undefined && !validations.timeBlockMeantimeDuration}>
                                <OutlinedInput
                                    id="outlined-adornment-timeblockmeantimeduration"
                                    type="number"
                                    defaultValue={values.timeBlockMeantimeDuration}
                                    onChange={handleNumberChange('timeBlockMeantimeDuration')}
                                    endAdornment={<InputAdornment position="end">minuten</InputAdornment>}
                                    aria-describedby="outlined-timeblockmeantimeduration-helper-text"
                                    required
                                    inputProps={{
                                        'aria-label': 'time block meantime duration',
                                        min: 0
                                    }}
                                />
                            </FormControl>
                        </>
                    )}
                    {activeStep === 3 && (
                        <Box className={classes.stepBody}>
                            <Typography variant="h6" className={classes.caption}>Hoe laat begint de bijeenkomst?</Typography>
                            <KeyboardTimePicker
                                id="start"
                                className={clsx(classes.margin, classes.textField)}
                                value={values.start ? values.start : null}
                                onChange={handleTimeChange('start')}
                                error = {validations.start !== undefined && !validations.start}
                                ampm={false}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                                required
                                fullWidth
                                invalidDateMessage="ongeldig tijdstip"
                                cancelLabel="Annuleren"
                                okLabel="OK"
                                format="HH:mm"
                            />
                        </Box>
                    )}
                    {activeStep === 4 && (
                        <>
                            {/* Timeblock count */}
                            <Typography variant="h6" className={classes.caption}>Aantal tijdsblokken berekenen op basis van:</Typography>
                            <FormControlLabel
                                className={classes.marginTop}
                                control={
                                    <Radio
                                        checked={values.timeBlockCountMode === 'total-groups'}
                                        onChange={handleTimeBlockCountMode}
                                        value="total-groups"
                                        color="primary"
                                    />
                                }
                                label="Totaal aantal genodigden"
                                labelPlacement="end"
                            />
                            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined"
                                error={values.timeBlockCountMode === 'total-groups' && validations.totalGroupCount !== undefined && !validations.totalGroupCount}>
                                <OutlinedInput
                                    type="number"
                                    defaultValue={values.totalGroupCount}
                                    onChange={handleNumberChange('totalGroupCount')}
                                    endAdornment={<InputAdornment position="end">huishoudens</InputAdornment>}
                                    aria-describedby="outlined-totalgroupcount-helper-text"
                                    required
                                    disabled={values.timeBlockCountMode !== 'total-groups'}
                                    inputProps={{
                                        'aria-label': 'total group count duration',
                                        min: 0
                                    }}
                                />
                            </FormControl>
                            <FormControlLabel
                                className={classes.marginTop}
                                control={
                                    <Radio
                                        checked={values.timeBlockCountMode === 'fixed'}
                                        onChange={handleTimeBlockCountMode}
                                        value="fixed"
                                        color="primary"
                                    />
                                }
                                label="Een vast aantal tijdsblokken"
                                labelPlacement="end"
                            />
                            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined"
                                error={values.timeBlockCountMode === 'fixed' && validations.timeBlockCount !== undefined && !validations.timeBlockCount}>
                                <OutlinedInput
                                    type="number"
                                    defaultValue={values.timeBlockCount}
                                    onChange={handleNumberChange('timeBlockCount')}
                                    endAdornment={<InputAdornment position="end">tijdsblokken</InputAdornment>}
                                    aria-describedby="outlined-timeBlockCount-helper-text"
                                    required
                                    disabled={values.timeBlockCountMode !== 'fixed'}
                                    inputProps={{
                                        'aria-label': 'time block count duration',
                                        min: 0
                                    }}
                                />
                            </FormControl>
                        </>                             
                    )}
                    {activeStep === 5 && (
                        <>
                            {/* Timeblock length */}
                            <Typography variant="h6" className={classes.caption}>Duur tijdsblok berekenen op basis van:</Typography>
                            <FormControlLabel
                                className={classes.marginTop}
                                control={
                                    <Radio
                                        checked={values.timeBlockDurationMode === 'calculated'}
                                        onChange={handleTimeBlockDurationMode}
                                        value="calculated"
                                        color="primary"
                                    />
                                }
                                label="Eindtijd bijeenkomst"
                                labelPlacement="end"
                            />
                            <KeyboardTimePicker
                                id="end"
                                required
                                className={classes.textField}
                                value={values.end ? values.end : null}
                                onChange={handleTimeChange('end')}
                                error={values.timeBlockDurationMode === 'calculated' && validations.end !== undefined && !validations.end}
                                ampm={false}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                                fullWidth
                                invalidDateMessage="ongeldig tijdstip"
                                cancelLabel="Annuleren"
                                okLabel="OK"
                                format="HH:mm"
                                disabled={values.timeBlockDurationMode !== 'calculated'}
                            />
                            <FormControlLabel
                                className={classes.marginTop}
                                control={
                                    <Radio
                                        checked={values.timeBlockDurationMode === 'fixed'}
                                        onChange={handleTimeBlockDurationMode}
                                        value="fixed"
                                        color="primary"
                                    />
                                }
                                label="Vaste duur tijdsblok"
                                labelPlacement="end"
                            />
                            <TextField
                                required
                                className={classes.textField}
                                defaultValue={values.timeBlockDuration}
                                onChange={handleNumberChange('timeBlockDuration')}
                                error={values.timeBlockDurationMode === 'fixed' && validations.timeBlockDuration !== undefined && !validations.timeBlockDuration}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">minuten</InputAdornment>
                                }}
                                inputProps={{
                                    'aria-label': 'time block duration',
                                    min: "1"
                                }}
                                disabled={values.timeBlockDurationMode !== 'fixed'}
                            />
                        </>
                    )}
                    {activeStep === 6 && (
                        // Summary
                        <>
                            <Box>
                                <Typography variant="h6" className={classes.caption}>Resultaat</Typography>
                                <Typography>Klik op <strong>{props.submitButtonText}</strong> om onderstaande tijdsblokken bevestigen.</Typography>
                                <TimeBlocksPreview eventDayConfiguration={calculatedValues} />
                            </Box>
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions style={{padding:0}}>
                <Box flexGrow={1}>
                    {/* {activeStep === 1 && 
                        <>
                            {validations.messages.length >= 1 && 
                                <Box className={classes.infoBox} bgcolor="error.main" color="white">
                                    {validations.messages.map(m => <Typography variant="body2">{m}</Typography>)}                            
                                </Box>
                            }                           
                        </>
                    } */}
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={(
                            <>
                                {activeStep < maxSteps - 1 && ( 
                                    <Button size="small" onClick={handleNext} disabled={!nextEnabled}>
                                        Volgende
                                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                    </Button>
                                )}
                                {activeStep === maxSteps - 1 && (
                                    <Button size="small" onClick={handleSubmit} variant="contained" color="primary" disabled={!nextEnabled}>
                                        {props.submitButtonText ? props.submitButtonText : 'Dag toevoegen'}
                                    </Button>
                                )}
                            </>
                        )}
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                Vorige
                            </Button>
                        }
                    />
                </Box>
             </DialogActions>            
        </Dialog>
    );
}));