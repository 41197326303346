import { Box, CircularProgress, createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { inject, observer } from "mobx-react";
import React from 'react';
import RootStore from '../stores/RootStore';


const styles = (theme: Theme): any => createStyles({
	root: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	}
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
	loading: boolean,
	text?: string
}


@inject('rootStore')
@observer
class Loading extends React.Component<IProps, {}> {

	render() {
		const { classes } = this.props;
		if (this.props.loading) {
			return (
				<Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>
					<Box>
						<CircularProgress />
					</Box>
					{this.props.text && 
						<Box>
							<Typography>{this.props.text}</Typography>
						</Box>
					}
				</Box>
			)
		};

		return (
			<>
				{this.props.children!}
			</>
		);
	}
}

export default withStyles(styles)(Loading);