import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Chip, Typography, Slide } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import RootStore from '../../../stores/RootStore';
import RefreshIcon from '@material-ui/icons/Refresh';
import grey from '@material-ui/core/colors/grey';
import Loading from '../../../components/Loading';
import pMinDelay from 'p-min-delay';
import EventDay, { IEventDayConfiguration } from '../../../models/EventDay';
import Event from '../../../models/Event';
import { DateTime } from 'luxon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Divider } from '@material-ui/core';
import DayRegistrations from './DayRegistrations';
import FaceIcon from '@material-ui/icons/Face';
import _ from 'lodash';
import { DateUtils } from '../../../lib/DateUtils';
import { SimpleDialog } from '../../../components/SimpleDialog';

const styles = makeStyles((theme) => ({
    day: {
        padding: theme.spacing(1),
        borderStyle: 'solid',
		borderWidth: 1,
        borderRadius: 4,
		borderColor: grey[400]
    },
    marginLeft: {
        marginLeft: theme.spacing(2)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    registrationChip: {
        marginLeft: theme.spacing(1)
    }
}));

interface IProps {
    rootStore?: RootStore;
    event: Event;
    onAddEventDayClick: () => void;
    onEditEventDayClick: (eventDay: EventDay) => void;
}

export default withTheme(inject('rootStore')(observer((props: IProps) => {
    
    const classes = styles();
    const [isReloading, setIsReloading] = useState(false);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [activationConfirmationDialogOpen, setActivationConfirmationDialogOpen] = useState<boolean>(false);
    const [activationDay, setActivationDay] = useState<EventDay>();
   
    const handleAdd = () => props.onAddEventDayClick();
    const handleEdit = (eventDay: EventDay) => () => props.onEditEventDayClick(eventDay);

    const handleRefresh = async () => {
        setIsReloading(true);
        await pMinDelay(props.event.reload({eventDays: true, registrations: true}), 1000);
        setIsReloading(false);
    }

    const handleAccordionClick = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);        
    };

    const handleActivateButtonClick = (day: EventDay) => (event: React.MouseEvent) => {
        setActivationDay(day);
        setActivationConfirmationDialogOpen(true);
    }

    const handleActivationRejected = () => {
        setActivationDay(undefined);
        setActivationConfirmationDialogOpen(false);
    }

    const handleActivationConfirmed = () => {
        activationDay?.activate();
        setActivationDay(undefined);
        setActivationConfirmationDialogOpen(false);
    }

    return (
        <>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="h5" gutterBottom>
                        Planning en aanmeldingen
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <Button color="primary" onClick={handleAdd}>Extra dag toevoegen</Button>
                    <RefreshIcon fontSize="large" className="pointer" onClick={handleRefresh} />
                </Box>
            </Box>
            <Box>
                <Loading loading={isReloading} text="Dagen worden geladen...">
                    {props.event.hasInactiveDays && 
                        <Box bgcolor="primary.main" color="white" p={2} mb={2}>
                            <Typography>
                                Één of meerdere dagen zijn nog niet geactiveerd. Genodigden kunnen zich alleen aanmelden bij actieve dagen. Om een dag te activeren klik je op de knop <strong>Activeren</strong> van de dag.
                            </Typography>
                        </Box>
                    }

                    <Accordion   
                        expanded={expanded === 'cancellations'} onChange={handleAccordionClick('cancellations')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Box display="flex" flexDirection="row" flexWrap="wrap" flexGrow={1} alignItems="center">
                                <Box flexBasis='33%' minWidth={200}>
                                    <Typography className={classes.heading}>Afmeldingen</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.secondaryHeading}>{props.event.cancellationCount} afmeldingen</Typography>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display="flex" flexDirection="row-reverse" flexWrap="wrap" flexGrow={1}>
                                {props.event.cancellations.map((c:any) => {
                                    return (
                                        <Box key={c.id}>
                                            <Chip label={c.name} color="primary" icon={<FaceIcon />} size="small" className={classes.registrationChip}/>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    {props.event.days.map(day => (
                        <Accordion key={day.id} expanded={expanded === day.id} onChange={handleAccordionClick(day.id)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Box display="flex" flexDirection="row" flexWrap="wrap" flexGrow={1} alignItems="center">
                                    <Box flexBasis='33%' minWidth={200}>
                                        <Typography className={classes.heading}>{day.date.toLocaleString(DateTime.DATE_HUGE)}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className={classes.secondaryHeading}>{day.slotsAvailable} van de {day.slotCount} plekken beschikbaar</Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="row" flexWrap="wrap" flexGrow={1} justifyContent="flex-end">
                                        <Box>
                                            <Chip className={classes.marginLeft} label={day.state === 'active' ? 'actief' : 'inactief'} color={day.state === 'active' ? 'primary' : 'default'} size="small" />
                                        </Box>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box flexGrow={1}>
                                    <DayRegistrations day={day} />                                    
                                </Box>
                            </AccordionDetails>
                            <Divider />
                            {day.state === 'inactive' && 
                                <AccordionActions>
                                    <Button className={classes.marginLeft} size="small" onClick={handleEdit(day)}>Wijzigen</Button>
                                    <Button className={classes.marginLeft} variant="contained" color="primary" size="small" onClick={handleActivateButtonClick(day)}>Activeren</Button>
                                </AccordionActions>}
                        </Accordion>
                    ))}
                </Loading>
            </Box>
            <SimpleDialog
                open={activationConfirmationDialogOpen}
                onCancel={handleActivationRejected}
                onOk={handleActivationConfirmed}
                title="Bevestig activatie"
                okButtonText="Ja, activeer"
                cancelButtonText="Nee, activeer niet">
                    <>  
                        <Typography paragraph={true}>Na activatie kan de indeling van de tijdsblokken van deze dag niet meer gewijzigd worden. De activatie van een dag kan niet meer ongedaan gemaakt worden.</Typography>
                        <Typography paragraph={true}>Weet je het zeker dat je <strong>{DateUtils.toDateString(activationDay?.date)}</strong> wilt activeren zodat genodigden de tijdsblokken op deze dag kunnen reserveren?</Typography>
                    </>
            </SimpleDialog>
        </>
    );
})));



