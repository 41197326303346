import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles, useTheme, withTheme } from '@material-ui/core/styles';
import { Box, Button, TextField, Typography, useMediaQuery } from '@material-ui/core';
import WizardDialog from '../../../components/wizards/WizardDialog';
import RootStore from '../../../stores/RootStore';
import emailValidator from 'email-validator';
import Event from '../../../models/Event';
import SignInDialog from './SignInDialog';

interface IValues {
    eventName: string | undefined,
    eventLocation: string | undefined,
    organizerEmail: string | undefined,
}

interface IValidation {
    eventName: boolean | undefined,
    eventLocation: boolean | undefined,
    organizerEmail: boolean | undefined,
    login: boolean | undefined
}

type SignInState = 'signin' | 'register' | 'forgetpassword' | 'authenticated';


const useStyles = makeStyles((theme) => ({
    caption: {
        fontWeight: 'bold',
        marginTop: theme.spacing(2)
    },
    textField: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2) 
    },
    marginTopBottom: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))

interface IProps {
    rootStore?: RootStore;
    open: boolean,
    onClose?: () => void;
    onSubmit?: (event: Event) => void;
}

export default withTheme(inject('rootStore')(observer((props: IProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [values, setValues] = useState<IValues>({
        eventName: undefined,
        eventLocation: undefined,
        organizerEmail: undefined
    });
    const [validations, setValidations] = useState<IValidation>({
        eventName: undefined,
        eventLocation: undefined,
        organizerEmail: undefined,
        login: undefined
    })
    const [signInDialogOpen, setSignInDialogOpen] = useState(false);

    const clear = () => {
        setValues({...values, 
            eventName: undefined,
            eventLocation: undefined,
            organizerEmail: undefined,
        });
        setValidations({...validations, 
            eventName: undefined,
            eventLocation: undefined,
            organizerEmail: undefined,
            login: undefined
        });
    }    

    const handleClose = () => {
        if (props.onClose) props.onClose();
        clear();
    }

    const handleSubmit = async () => {
        let event = props.rootStore!.eventStore.createEvent();
        event.setLocation(values.eventLocation!);
        event.setTitle(values.eventName!);
        await event.save();

        if (props.onSubmit) props.onSubmit(event);
        clear();
    }

    const handleTextChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({...values, [prop]: event.target.value});
    } 
    
    const handleStepValidation = (index: number) => {
        switch (index) {
            case 0:
                const eventName = values.eventName !== undefined;
                setValidations({...validations, eventName});
                return eventName;
            case 1:
                const eventLocation = values.eventLocation !== undefined;
                setValidations({...validations, eventLocation});
                return eventLocation;
            case 2:
                const organizerEmail = values.organizerEmail !== undefined;
                setValidations({...validations, organizerEmail});
                return props.rootStore!.authStore.isAuthenticated;
        }
        return true;
    }

    return (
        <>
            <WizardDialog
                title="Plan je bijeenkomst"
                finishButtonText="Afronden"
                finishButtonDisabled={!props.rootStore!.authStore.isAuthenticated}
                fullScreen={fullScreen} 
                onClose={handleClose} 
                onSubmit={handleSubmit}
                onValidateStep={handleStepValidation}
                open={props.open}
                maxWidth="sm"
                fullWidth={true}
                scroll="paper"
                disableBackdropClick>
                <Box>
                    <Typography variant="h6" className={classes.caption}>Welke naam wil je aan je bijeenkomst geven?</Typography>
                    <TextField
                        id="eventName"
                        className={classes.textField}
                        required
                        defaultValue={values.eventName}
                        onChange={handleTextChange('eventName')}
                        error={validations.eventName !== undefined && !validations.eventName}
                        inputProps={{
                            'aria-label': 'event name',
                        }}
                        helperText={validations.eventName !== undefined && !validations.eventName ? 'Je moet een naam opgeven.' : ''}
                    />
                </Box>
                <Box>
                    <Typography variant="h6" className={classes.caption}>Waar is je bijeenkomst?</Typography>
                    <Typography>Het moet voor je genodigden duidelijk zijn waar ze moeten zijn.</Typography>
                    <TextField
                        id="eventLocation"
                        className={classes.textField}
                        required
                        defaultValue={values.eventLocation}
                        onChange={handleTextChange('eventLocation')}
                        error={validations.eventLocation !== undefined && !validations.eventLocation}
                        inputProps={{
                            'aria-label': 'event location',
                        }}
                        helperText={validations.eventLocation !== undefined && !validations.eventLocation ? 'Je moet een locatie opgeven.' : ''}
                    />
                </Box>
                <Box>
                    {!props.rootStore!.authStore.isAuthenticated &&
                        <>
                            <Typography>
                                Om je bijeenkomst af te ronden moet je inloggen of registreren.
                            </Typography>
                            <Box display="flex" justifyContent="center" className={classes.marginTopBottom}>
                                <Button variant="contained" color="primary" onClick={() => setSignInDialogOpen(true)}>Inloggen of Registreren</Button>
                            </Box>
                        </>
                    }
                    {props.rootStore!.authStore.isAuthenticated &&
                        <Typography style={{ marginTop: '20px' }}>
                            Klik op de knop <strong>Afronden</strong> om je bijeenkomst te creëren. Je wordt automatisch doorgestuurd naar de beheerpagina, waar je de bijeenkomst verder kunt indelen.
                        </Typography>
                    }
                    <SignInDialog open={signInDialogOpen} onClose={() => setSignInDialogOpen(false)}/>
                </Box>
            </WizardDialog>
        </>
    );
})));