import { withTheme, makeStyles, Link } from '@material-ui/core';
import { Box, Container } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    footerSection: {
		backgroundColor: theme.palette.text.primary,
		color: 'white'
	},
    footerLink: {
		color: 'white',
        cursor: 'pointer'
	},
	footerItem: {
		margin: theme.spacing(2)
	}
}));


interface IProps {
    onCookieSettingsClick: () => void
}

const Footer = withTheme((props: IProps) => {
    const classes = useStyles();

    const onCookieSettingsClick = () => {
        props.onCookieSettingsClick();
    }

    return (
        <Box className={`section ${classes.footerSection}`}>
            <Container maxWidth="md">
                <Box display="flex" flexWrap="wrap" justifyContent="flex-end">
                    <Box className={classes.footerItem}>
                        <Link className={classes.footerLink} component={RouterLink} to='/about-us/terms-of-use'>Gebruiksvoorwaarden</Link>
                    </Box>
                    <Box className={classes.footerItem}>
                        <Link className={classes.footerLink} component={RouterLink} to='/about-us/privacy-statement'>Privacybeleid</Link>
                    </Box>
                    <Box className={classes.footerItem}>
                        <Link className={classes.footerLink} component={RouterLink} to='/about-us/cookie-policy'>Cookiebeleid</Link>
                    </Box>
                    <Box className={classes.footerItem}>
                        <Link className={classes.footerLink} onClick={onCookieSettingsClick}>Cookie instellingen</Link>
                    </Box>
                    <Box className={classes.footerItem}>
                        <Link className={classes.footerLink} component={RouterLink} to='/about-us/contact'>Contact</Link>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
});

export { Footer }
