import { Box, Chip, Typography } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { DateUtils } from '../../../lib/DateUtils';
import EventDay from '../../../models/EventDay';
import FaceIcon from '@material-ui/icons/Face';
import { Registration } from '../../../models/Registration';
import RootStore from '../../../stores/RootStore';
import clsx from 'clsx';
import _ from 'lodash';
import TimeBlockV2 from '../../../models/TimeBlockV2';
import TimeBlock from '../../../models/TimeBlock';

const useStyles = makeStyles((theme) => ({
    row: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderColor: theme.palette.divider,
        '&:not(:last-child)': {
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
        }
    },
    totalRow: {
        marginTop: '2px',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderColor: theme.palette.divider
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    registrationChip: {
        marginLeft: theme.spacing(1)
    }
}));

interface IProps {
    rootStore?: RootStore,
    day: EventDay
}

export default withTheme(inject('rootStore')(observer((props: IProps) => {
    const classes = useStyles();
    const [totalAdults, setTotalAdults] = useState<number>();
    const [totalChildren, setTotalChildren] = useState<number>();

    useEffect(() => {
        let registrations = props.rootStore!.registrationStore.registrationsByEventDay[props.day.id];
        console.log('### regs', props.day.id, registrations, props.rootStore!.registrationStore.registrationsByEventDay);
        setTotalAdults(_.sumBy(registrations, r => r.numberOfAdults ?? 0));
        setTotalChildren(_.sumBy(registrations, r => r.numberOfChildren ?? 0));
    }, [props.day])

    return (
        <Box flexGrow={1}>
            {props.day.timeBlocks.map(tb => <TimeBlockRow key={tb.id} timeBlock={tb} />)}
            <Box display="flex" flexDirection="row" justifyContent="flex-end" className={
                clsx(classes.row, classes.totalRow)}>
                <Box flexGrow={1}>
                    <Typography>Totaal</Typography>
                </Box>
                <Box pl={2}>
                    <Typography>{totalAdults} volwassene{totalAdults !== undefined && totalAdults !== 1 ? 'n' : ''}</Typography>
                </Box>
                <Box pl={2}>
                    <Typography>{totalChildren} kind{totalChildren !== undefined && totalChildren !== 1 ? 'eren' : ''}</Typography>
                </Box>
            </Box>
        </Box>
    );
})));


const TimeBlockRow = withTheme(inject('rootStore')((props: {
    rootStore?: RootStore,
    timeBlock: TimeBlockV2
}) => {
    const classes = useStyles();
    const [registrations, setRegistrations] = useState<Registration[]>([]);

    useEffect(() => {
        setRegistrations(props.rootStore!.registrationStore.registrationsByTimeBlock[props.timeBlock.id] ?? []);
    }, [props.timeBlock]);

    return (
        <Box className={classes.row}>
            <Box display="flex" flexDirection="row" flexWrap="wrap" flexGrow={1} alignItems="top">
                <Box flexBasis='20%' minWidth={100}>
                    <Typography>
                        {DateUtils.toTimeString(props.timeBlock.start)} - {DateUtils.toTimeString(props.timeBlock.end)}
                    </Typography>
                </Box>
                <Box flexBasis='20%' minWidth={100}>
                    {props.timeBlock.slotsAvailable > 0 && <Typography className={classes.secondaryHeading}>{props.timeBlock.slotsAvailable} plek{props.timeBlock.slotsAvailable > 1 ? 'ken': ''} beschikbaar</Typography>}
                    {props.timeBlock.slotsAvailable === 0 && <Typography className={classes.secondaryHeading}>vol</Typography>}
                </Box>
                <Box flexGrow={1}>
                    {registrations.map((r, i) => 
                        <Box key={r.id} display="flex" flexDirection="row" pb={1}>
                            <Box flexGrow={1}>
                                <RegistrationChip key={r.id} registration={r} />
                            </Box>
                            <Box pr={2}>
                                <Typography className={classes.secondaryHeading}>{r.numberOfAdults} volwassene{r.numberOfAdults !== undefined && r.numberOfAdults !== 1 ? 'n' : ''}</Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.secondaryHeading}>{r.numberOfChildren} kind{r.numberOfChildren !== undefined && r.numberOfChildren !== 1 ? 'eren' : ''}</Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}));


const RegistrationChip = withTheme((props: {
    registration: Registration;
}) => {
    const classes = useStyles();
    return (
        <Chip 
            key={props.registration.id} 
            label={props.registration.name} 
            color="primary" 
            icon={<FaceIcon />} 
            size="small" 
            className={classes.registrationChip} />
    );
})

