import { action, observable } from 'mobx';
import EventCapacity from '../models/EventCapacity';
import TransportLayer from './TransportLayer';


export default class EventCapacityStore {
	transportLayer: TransportLayer;
	@observable eventCapacity: EventCapacity | undefined = undefined;

	constructor(transportLayer: TransportLayer, initialData: any = {}) {
		this.eventCapacity = initialData.event;
		this.transportLayer = transportLayer;
	}

	@action clear = async () => {
		this.eventCapacity = undefined;
	}

	@action loadEventCapacity = async (eventId: string) => {
		let serverData = await this.transportLayer.fetchEventCapacity(eventId);
		this.eventCapacity = new EventCapacity(this, eventId);
		this.eventCapacity.updateFromServer(serverData);
	}
}