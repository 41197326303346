import { Box, Button, createStyles, Grid, Link, TextField, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import emailValidator from 'email-validator';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import RootStore from '../stores/RootStore';


const styles = (theme: Theme): any => createStyles({
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
	onSignIn?: () => void,
	onPasswordReset?: () => void
}


@inject('rootStore')
@observer
class SignInForm extends React.Component<IProps, {}> {

	@observable private username: string = '';
	@observable private password: string = '';
	@observable private attempt: number = 0;
	@observable private signInFailed: boolean = false;
	@observable private activeForm: 'signIn' | 'passwordReset' | 'emailSent' = 'signIn';

	@computed private get usernameValid(): boolean {
		return this.username.length > 0 && emailValidator.validate(this.username);
	}
	@computed private get passwordValid(): boolean {
		return this.password.length > 0;
	}

	@action private signIn = async () => {
		this.attempt++;
		this.signInFailed = false;
		if (await this.props.rootStore!.authStore.signIn(this.username, this.password)) {
			if (this.props.onSignIn) this.props.onSignIn();
		} else {
			this.signInFailed = true;
		}
	}

	@action private showPasswordReset = () => {
		this.activeForm = 'passwordReset';

	}

	@action private sendEmail = async () => {
		await this.props.rootStore!.authStore.transportLayer.requestPasswordReset(this.username);
		this.activeForm = 'emailSent';
	}

	private onCloseAfterPasswordReset = async () => {
		if (this.props.onPasswordReset) this.props.onPasswordReset();
	}

	render() {
		return (
			<Box>
				{this.activeForm === 'signIn' && this.renderSignInForm()}
				{this.activeForm === 'passwordReset' && this.renderForgetPasswordForm()}
				{this.activeForm === 'emailSent' && this.renderEmailSent()}
			</Box>
		);
	}

	private renderSignInForm() {
		return (
			<>
				{this.signInFailed &&
					<Alert severity="error">Inloggen mislukt. Onjuiste gebruikersnaam en/of wachtwoord.</Alert>
				}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							id="username"
							label="Gebruikersnaam"
							value={this.username}
							onChange={(e) => this.username = e.target.value}
							error={this.attempt > 0 && (!this.usernameValid || this.signInFailed)}
							fullWidth
							required />
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="password"
							label="Wachtwoord"
							type="password"
							value={this.password}
							onChange={(e) => this.password = e.target.value}
							error={this.attempt > 0 && (!this.passwordValid || this.signInFailed)}
							fullWidth
							required />
					</Grid>
					<Grid item xs={12}>
						<Link onClick={this.showPasswordReset} className={`pointer`}>
							<Typography variant="caption">Wachtwoord vergeten?</Typography>
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Button color="primary" variant="contained" onClick={this.signIn}>Inloggen</Button>
					</Grid>
				</Grid>
			</>
		);
	}

	private renderForgetPasswordForm() {
		return (
			<>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h6">
							Stel je wachtwoord opnieuw in.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography noWrap={false}>
							Vul je email adres in. We sturen je een email om je wachtwoord opnieuw in te stellen.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="email"
							label="Email"
							value={this.username}
							onChange={(e) => this.username = e.target.value}
							error={this.attempt > 0 && !this.usernameValid}
							fullWidth
							required />
					</Grid>
					<Grid item xs={12}>
						<Button color="primary" variant="contained" onClick={this.sendEmail}>Verstuur email</Button>
					</Grid>
				</Grid>
			</>
		);
	}

	private renderEmailSent() {
		return (
			<>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography noWrap={false}>
							We hebben je een email verzonden om je wachtwoord opnieuw in te stellen.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Button color="primary" variant="contained" onClick={this.onCloseAfterPasswordReset}>Sluiten</Button>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default withStyles(styles)(SignInForm);
