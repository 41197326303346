import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CookiePreferences } from '../../../models/CookiePreferences';


interface IProps {
    open: boolean,
    onClose: () => void,
    cookiePreferences: CookiePreferences
}

const CookiePreferencesDialog = withTheme((props: IProps) => {

    const [advertisement, setAdvertisement] = useState(false);
    const [optimization, setOptimization] = useState(false);

    useEffect(() => {
        // default enabled in form when no choices made yet.
        if (props.cookiePreferences.functional === undefined) {
            setAdvertisement(true);
            setOptimization(true);
        } else {
            setAdvertisement(Boolean(props.cookiePreferences.advertisement));
            setAdvertisement(Boolean(props.cookiePreferences.optimization));
        }
    }, [])

    const onAdvertisementChanged = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setAdvertisement(checked);
    }

    const onOptimizationChanged = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setOptimization(checked);
    }

    const handleSave = () => {
        const cp = props.cookiePreferences;
        cp.functional = true;
        cp.analytical = true;
        cp.advertisement = advertisement;
        cp.optimization = optimization;
        cp.save();
        props.onClose();
    }

    return (
        <Dialog open={props.open}>
            <DialogTitle>
                Cookies op onze website
            </DialogTitle>
            <DialogContent>
                <Typography paragraph={true}>
                    We plaatsen verschillende soorten cookies op onze website. Omdat we het belangrijk vinden dat je zelf controle hebt 
                    over jouw online privacy, informeren we je graag over welke cookies we gebruiken en waarvoor we deze gebruiken.
                    Functionele cookies plaatsen we altijd. Deze zijn noodzakelijk om de website goed te laten werken. Ook plaatsen we 
                    analytische cookies. Hiermee meten we het gebruik van onze website en weten we hoe we die kunnen verbeteren. 
                    Uit deze cookies kunnen we geen persoonlijke gegevens herleiden.
                </Typography>
                <Typography paragraph={true}>
                    Geef aan of je toestemming geeft voor de volgende cookies:
                </Typography>                
                <Box display="flex" flexDirection="row">
                    <Box>
                        <Checkbox
                            checked={advertisement}
                            onChange={onAdvertisementChanged}
                            color="primary"
                            inputProps={{ 'aria-label': 'advertisement checkbox' }}
                        />
                    </Box>
                    <Box>
                        <Typography>
                            Advertentie cookies zodat ik gepersonaliseerde aanbiedingen kan zien op andere websites. 
                            Met deze cookies kunnen advertentienetwerken je internetgedrag volgen.
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box>
                        <Checkbox
                            checked={optimization}
                            onChange={onOptimizationChanged}
                            color="primary"
                            inputProps={{ 'aria-label': 'optimalization checkbox' }}
                        />
                    </Box>
                    <Box>
                        <Typography>
                            Optimalisatie cookies zodat ik feedback kan geven over mijn gebruikerservaring op de website.
                        </Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleSave}>Opslaan en sluiten</Button>
            </DialogActions>
        </Dialog>
    );
});

export { CookiePreferencesDialog }
