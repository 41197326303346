import { action, observable } from 'mobx';
import Event from '../models/Event';
import EventDay from '../models/EventDay';
import TransportLayer from './TransportLayer';
import { DateTime } from 'luxon';
import _ from 'lodash';


export default class EventDayStore {
	transportLayer: TransportLayer;
	@observable days: EventDay[] = [];
	@observable isLoading: boolean = false;;
	private event: Event | undefined;

	constructor(transportLayer: TransportLayer, initialData: any = {}) {
		this.transportLayer = transportLayer;
		if (initialData.eventDays) this.days = initialData.eventDays;
	}

	@action clear = async () => {
		this.days = [];
	}

	@action createEventDay = (date: DateTime) => {
		let d = new EventDay(this, this.event!.id!, date);
		this.days.push(d);
		this.event?.addDay(d);
		this.sortDays();
		return d;
	}

	@action sortDays = () => {
		this.days = _.sortBy(this.days, d => d.date);
	}

	@action loadDays = async (event: Event) => {
		this.isLoading = true;
		this.clear();
		this.event = event;
		this.event.days = [];
		let days = await this.transportLayer.fetchEventDays(event.id!);
		if (days) {
			days.forEach((d: any) => {
				let day = new EventDay(this, this.event!.id!, DateTime.fromISO(d.date));
				day.updateFromServer(d);
				this.days.push(day);
				event.addDay(day);
			});
		}
		this.isLoading = false;
	}
}