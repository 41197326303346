import AuthStore from "./AuthStore";
import EventCapacityStore from "./EventCapacityStore";
import EventDayStore from "./EventDayStore";
import EventStore from "./EventStore";
import ParticipationStore from "./ParticipationStore";
import PublicStore from "./PublicStore";
import RouterStore from "./RouterStore";
import TransportLayer from "./TransportLayer";
import { RegistrationStore } from './RegistrationStore';

export default class RootStore {
	authStore: AuthStore;
	routerStore: RouterStore;
	eventStore: EventStore;
	eventDayStore: EventDayStore;
	registrationStore: RegistrationStore;
	eventCapacityStore: EventCapacityStore;
	participationStore: ParticipationStore;
	publicStore: PublicStore;


	constructor(routerStore: RouterStore) {
		const transportLayer = new TransportLayer();

		this.routerStore = routerStore;
		this.authStore = new AuthStore(this, transportLayer);
		this.eventStore = new EventStore(this, transportLayer);
		this.eventDayStore = new EventDayStore(transportLayer);
		this.registrationStore = new RegistrationStore(this, transportLayer);
		this.eventCapacityStore = new EventCapacityStore(transportLayer);		
		this.participationStore = new ParticipationStore(transportLayer);
		this.publicStore = new PublicStore(transportLayer);
	}
}