import { Box, Button, Container, createStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { action, observable } from 'mobx';
import { inject, observer } from "mobx-react";
import React from 'react';
import { DateUtils } from '../../lib/DateUtils';
import RootStore from '../../stores/RootStore';
import StartDialog from '../public/components/StartDialog';

const styles = (theme: Theme): any => createStyles({
	timeBlock: {
		padding: theme.spacing(1),
		backgroundColor: 'white',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRadius: 4,
		borderColor: grey[400]
	},
});

interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
	eventId: string
}


@inject('rootStore')
@observer
class MyEvents extends React.Component<IProps, {}> {

	@observable private eventsLoading = false;
	@observable private isGetStartedDialogOpen = false;

	@action handleAddEvent = () => {
		this.isGetStartedDialogOpen = true;
	}
	@action handleGetStartedDialogClose = () => {
		this.isGetStartedDialogOpen = false;
	}

	render() {
		//const { classes } = this.props;
		return (
			<>
				<Box className="page-content">
					<Container maxWidth="md">
						<Box className="page-title">
							<Typography variant="h4">
								Mijn bijeenkomsten
							</Typography>
						</Box>
					</Container>
				</Box>
				<Box className="section">
					<Container maxWidth="md">
						<Box display="flex" justifyContent="flex-start">
							<Box flexGrow={1}>
								<Typography variant="h5">
									Mijn georganiseerde bijeenkomsten
								</Typography>
							</Box>
							<Box>
								<Button variant="contained" onClick={this.handleAddEvent} color="primary">Nieuwe bijeenkomst</Button>
							</Box>
						</Box>
						
						<TableContainer>
							<Table aria-label="Mijn georganiseerde bijeenkomsten">
								<TableHead>
									<TableRow>
										<TableCell>Naam</TableCell>
										<TableCell>Periode</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.props.rootStore!.eventStore.organizedEvents.map((e, i) => {
										return (
											<TableRow
												key={e.id}
												className={`pointer`}
												hover
												onClick={() => this.props.rootStore!.routerStore.push(`/my/events/${e.id}`)}>
												<TableCell>{e.title}</TableCell>
												<TableCell>
													{e.minDayDate && e.maxDayDate && e.minDayDate.toMillis() === e.maxDayDate.toMillis() &&
														<>{DateUtils.toDateString(e.minDayDate!)}</>}
													{e.minDayDate && e.maxDayDate && e.minDayDate.toMillis() !== e.maxDayDate.toMillis() &&
														<>{DateUtils.toDateString(e.minDayDate!)} - {DateUtils.toDateString(e.maxDayDate!)}</>}
													{(!e.minDayDate || !e.maxDayDate) &&
														<>Planning ontbreekt</>}
												</TableCell>
											</TableRow>);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Container>
				</Box>
				<StartDialog 
					open={this.isGetStartedDialogOpen} 
					onClose={this.handleGetStartedDialogClose}
					onSubmit={(event) => this.props.rootStore!.routerStore.push(`/my/events/${event.id}`)}
				/>
			</>
		);
	}

	componentDidMount = async () => {
		if (!this.eventsLoading) {
			this.eventsLoading = true;
			await this.props.rootStore!.eventStore.loadOrganizedEventsForUser(this.props.rootStore!.authStore.user!.id!);
			this.eventsLoading = false;
		}
	}
}

export default withStyles(styles)(MyEvents);