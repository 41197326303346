import { Box, Typography, Paper } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { DateUtils } from '../../../lib/DateUtils';
import PublicTimeBlockV2 from '../../../models/PublicTimeBlockV2';

const styles = makeStyles((theme) => ({
    row: {
        padding: theme.spacing(1),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        }
    },
    pointer: {
        cursor: 'pointer'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeadingColor: {
        color: theme.palette.text.secondary,
        },
    selected: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
    },
    occupied: {
        backgroundColor: theme.palette.divider
    }
}));

interface IProps {
    timeBlock: PublicTimeBlockV2,
    selected?: boolean;
    onClick?: () => void;
}

export default withTheme(observer((props: IProps) =>    {
    const classes = styles();

    const handleClick = () => {
        if (props.onClick) props.onClick();
    }

    return (
        <Paper variant="outlined" className={clsx(classes.row, props.selected && classes.selected, !props.timeBlock.isOccupied && classes.pointer, props.timeBlock.isOccupied && classes.occupied)} onClick={handleClick}>
            <Box display="flex" flexDirection="row" flexWrap="wrap" flexGrow={1} alignItems="center" borderColor="divider">
                <Box flexBasis='20%' minWidth={100}>
                    <Typography>
                        {DateUtils.toTimeString(props.timeBlock.start)} - {DateUtils.toTimeString(props.timeBlock.end)}
                    </Typography>
                </Box>
                <Box>
                    {!props.timeBlock.isOccupied && <Typography className={clsx(classes.secondaryHeading, !props.selected && classes.secondaryHeadingColor)}>{props.timeBlock.slotsAvailable} plek{props.timeBlock.slotsAvailable > 1 ? 'ken': ''} beschikbaar</Typography>}
                    {props.timeBlock.isOccupied && <Typography className={clsx(classes.secondaryHeading, !props.selected && classes.secondaryHeadingColor)}>vol</Typography>}
                </Box>
            </Box>
        </Paper>
    );
}));