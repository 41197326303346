import { createStyles, TextField, Theme, WithStyles, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CopyIcon from '@material-ui/icons/FileCopy';
import copyToClipboard from 'copy-to-clipboard';
import { action, observable } from 'mobx';
import { inject, observer } from "mobx-react";
import React from 'react';
import settings from '../Config';
import RootStore from '../stores/RootStore';


const styles = (theme: Theme): any => createStyles({
});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore,
	eventId: string
}


@inject('rootStore')
@observer
class EventShareLink extends React.Component<IProps, {}> {

	private shareLink: string;
	@observable private isCopied: boolean = false;

	constructor(props: IProps) {
		super(props);
		this.shareLink = `${settings.webBaseUrl}/events/${this.props.eventId}/register`;
	}

	@action private copyShareLink = async () => {
		await copyToClipboard(this.shareLink);
		this.isCopied = true;
	}

	render() {
		//const { classes } = this.props;
		return (
			<>
				<TextField
					id="share-link"
					label=""
					value={this.shareLink}
					InputProps={{
						endAdornment:
							<IconButton onClick={this.copyShareLink}>
								{!this.isCopied && <CopyIcon />}
								{this.isCopied && <CheckIcon />}
							</IconButton>
					}}
					fullWidth
				/>
			</>
		);
	}
}

export default withStyles(styles)(EventShareLink);