class LocalStorage {

    static getItem = <T>(key: string) => {
        const item = localStorage.getItem(key);
        if (!item) return undefined;

        const record = JSON.parse(item);
        if (record && (record.expireAt === undefined || new Date().getTime() < record.expireAt)) {
            return record.value as T;
        } else {
            localStorage.removeItem(key);
        }
    }

    static setItem = <T>(key: string, value: T, expireAfterMs: number | undefined) => {
        const record = {
            expireAt: expireAfterMs ? new Date().getTime() + expireAfterMs : undefined,
            value: JSON.stringify(value)
        };
        localStorage.setItem(key, JSON.stringify(record));
    }
}

export { LocalStorage }