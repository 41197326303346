import { DateTime } from 'luxon';
import { action, computed, observable } from 'mobx';
import PublicEventDay from './PublicEventDay';


export default class PublicTimeBlockV2 {
	@observable id: string = '';
	@observable start: DateTime = DateTime.invalid(' ');
	@observable end: DateTime = DateTime.invalid(' ');
	@observable maxNumberOfRegistrations: number = 0;
	@observable slotsAvailable: number = 0;
	@observable numberOfRegistrations: number = 0;
	@observable registrations: TimeBlockRegistration[] = [];
	eventDay: PublicEventDay | undefined;

	@computed get isOccupied() {
		return this.slotsAvailable === 0;
	}

	constructor(eventDay: PublicEventDay | undefined = undefined) {
		this.eventDay = eventDay;
	}

	@action register = (id: string, name: string) => {
		if (this.registrations.length < this.maxNumberOfRegistrations - 1) {
			this.registrations.push(new TimeBlockRegistration(id, name));
		} else {
			throw Error("Time block is full");
		}
	}

	@action updateFromServer = (data: any) => {
		this.id = data.id;
		this.start = DateTime.fromISO(data.start);
		this.end = DateTime.fromISO(data.end);
		this.maxNumberOfRegistrations = +data.maxNumberOfRegistrations;
		this.slotsAvailable = data.slotsAvailable;
		this.numberOfRegistrations = data.numberOfRegistrations;
	}
}

class TimeBlockRegistration {
	@observable id: string | undefined;
	@observable name: string | undefined;

	constructor(participationId: string, name: string) {
		this.id = participationId;
		this.name = name;
	}
}
