import { Box, Container, createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { inject, observer } from "mobx-react";
import React from 'react';
import RootStore from '../stores/RootStore';
import SignInForm from './SignInForm';


const styles = (theme: Theme): any => createStyles({

});


interface IProps extends WithStyles<typeof styles> {
	rootStore?: RootStore
}


@inject('rootStore')
@observer
class AuthenticatedArea extends React.Component<IProps, {}> {

	render() {
		return (
			<>
				{this.props.rootStore!.authStore.isAuthenticated && this.props.children}
				{!this.props.rootStore!.authStore.isAuthenticated &&
					<>
						<Box className="page-content">
							<Container maxWidth="md">
								<Box className="page-title">
									<Typography variant="h4">
										Inloggen vereist
									</Typography>
								</Box>
								<SignInForm />
							</Container>
						</Box>
					</>}
			</>
		);
	}

	componentDidMount() {
		if (!this.props.rootStore!.authStore.isAuthenticated) {
			//this.props.rootStore!.routerStore.push(`/signin?return=${window.location.href}`);
		}
	}
}

export default withStyles(styles)(AuthenticatedArea);