import { action, computed, observable } from 'mobx';


export default class TimeBlock {
	id: string;
	start: Date;
	end: Date;
	@observable maxNumberOfRegistrations: number = 0;
	@observable registrations: string[] = [];

	@computed get isOccupied() {
		return this.registrations.length >= this.maxNumberOfRegistrations;
	}

	@computed get spotsAvailable() {
		return this.maxNumberOfRegistrations - this.registrations.length;
	}

	constructor(id: string, start: Date, end: Date, maxNumberOfRegistrations: number) {
		this.id = id;
		this.start = start;
		this.end = end;
		this.maxNumberOfRegistrations = maxNumberOfRegistrations;
	}

	@action register = (name: string) => {
		if (this.registrations.length < this.maxNumberOfRegistrations - 1) {
			this.registrations.push(name);
		} else {
			throw Error("Time block is full");
		}
	}

	@action updateFromServer = (data: any) => {
		this.start = new Date(data.start);
		this.end = new Date(data.end);
		this.maxNumberOfRegistrations = data.maxNumberOfRegistrations;
		this.registrations.slice(0, this.registrations.length);
		data.registrations.forEach((r: any) => this.registrations.push(r));
	}
}