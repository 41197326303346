import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, makeStyles, withTheme } from '@material-ui/core';
import RootStore from '../../stores/RootStore';
import { inject, observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}))

interface IProps {
	rootStore?: RootStore,
	eventId: string,
    loadDays?: boolean,
    loadRegistrations?: boolean
}

const EventLoader = withTheme(inject('rootStore')(observer((props: React.PropsWithChildren<IProps>) => {
    const classes = useStyles();
    const eventStore = props.rootStore!.eventStore;
    const eventDayStore = props.rootStore!.eventDayStore;
    const registrationStore = props.rootStore!.registrationStore;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        eventStore.loadEvent(props.eventId).then(() => {
            // 1. Load event
            if (props.loadDays !== undefined && props.loadDays) {
                // 2. load event dags & registrations
                const event = eventStore.get(props.eventId)!;
                Promise.all([
                    eventDayStore.loadDays(event),
                    registrationStore.loadForEvent(event)
                ]).then(() => setIsLoading(false));
            } else 
            {
                setIsLoading(false);
            }
        });
    }, [props.eventId, props.loadDays, eventStore, eventDayStore]);

    return (
        <>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {!isLoading && 
                <>
                    {props.children}
                </>
            }
        </>
    );
})));
export { EventLoader };

